/*----------------------------------------------------------------*/
/*  Print
/*----------------------------------------------------------------*/
@media all {
    /* Never show page breaks in normal view */
    .page-break-after,
    .page-break-before {
        display: none;
    }
    .show-on-print{
        display: none;
       }
}

@media print {
   .show-on-print{
    display: block;
   }
    /* html and body tweaks */
    html, body {
        height: auto !important;
        overflow: initial !important;
        background: none
    }

    /* Page breaks */
    .page-break-after {
        display: block;
        page-break-after: always;
        position: relative;
    }
    .page-break-auto{
        page-break-before: auto;
    }
    .page-break-before {
        display: block;
        page-break-before: always;
        position: relative;
    }

    /* General styles */
    #fuse-toolbar,
    #fuse-footer,
    #fuse-navbar,
    #fuse-settings-presets,
    #fuse-layout .ps > .ps__rail-x,
    #fuse-layout .ps > .ps__rail-y {
        display: none !important;
    }

    #fuse-layout .ps {
        overflow: visible !important;
    }
}

.rejecttext {
    font-size: 8px;
    line-height: 8px;
    float: right;
}
@media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-after: always;
    }
  }
  .imgicon {
    width: auto !important;
  }