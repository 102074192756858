.Red {
  color: #f00;
}

.boderanimated {
  background: linear-gradient(90deg, #f40000 50%, transparent 50%), linear-gradient(90deg, #f40000 50%, transparent 50%), linear-gradient(0deg, #f40000 50%, transparent 50%), linear-gradient(0deg, #f40000 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 260px 5px, 260px 5px, 5px 260px, 5px 260px;
  padding: 10px;
  animation: border-dance 4s infinite linear;
}

.animateDefult {
  width: 100%;
  height: 100%;
  /* overflow-y: scroll; */
  border-radius: 20px;
}
@keyframes border-dance {
  0% {
    background-position: 0 0, 100% 100%, 0 100%, 100% 0;
  }
  100% {
    background-position: 100% 0, 0 100%, 0 0, 100% 100%;
  }
}

:focus-visible {
  outline: 0px dashed darkorange;
}
@media print {
  div {
      break-inside: avoid;
    }
  }
