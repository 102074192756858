/*top right bottom left*/
@page {
    size: A4;
    margin: 17mm 8mm 1mm 8mm;
 
  }


  .header{
    display: none;
  }

  .footer{
    display: none;
   
  }
  td.MuiTableCell-alignRight {
    padding: 0 10px !important;
}
 

@media print{
    
    .Report {
      justify-content: center;
       overflow: visible !important;
       break-inside: avoid !important;
       
       
    }
    .header{
        display: inherit;
        position: fixed;
        top: 0cm;
    }
    .footer{
        display: flex;
        position: fixed;
        bottom: 0.3cm;
        right: 0.5cm;
        
        break-inside: avoid;
       
      
        
    }

    .job{
        
       /* display: inline-block;*/
        break-inside: avoid;
        
       
        
        
    }

    .halfborder {
      border-top:1px solid black;
      position: relative;
    }
    .halfborder:after {
      padding:0;margin:0;display:block;/* probably not really needed? */
      content: "";
      width:50%;
      height:1.1px;
      background-color:white;
      position: absolute;
      right:0;
      top:-1px;
    }

   
  
}