@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body{
    font-family: 'Poppins', sans-serif;
}
.jobheading {
    font-weight: 700;
    letter-spacing: -1px;
    font-size: 40px;
    line-height: 63px;
}
.job-billing {
    font-size: 15px;
    font-weight: 500;
    margin: 10px 0 0;
}

.MuiContainer-maxWidthLg {
    max-width: unset;
    padding: 0;
}
.cardinner {
    width: 32.5%;
    border: 1px solid #000;
    border-radius: 14px;
    padding: 30px 35px;
}
.cardinner h2 {
    font-size: 27px;
    font-weight: 700;
    line-height: 35px;
    margin: 0 0 6px;
}
.cardinner h3 {
    font-size: 15px;
    font-weight: 400;
    margin: 0 0 30px;
}
.cardinner label {
    position: unset !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #000 !important;
    font-family: 'Poppins', sans-serif !important;
    transform: unset;
    translate: unset;
    margin: 0 0 7px;
}
.MuiOutlinedInput-root.MuiInputBase-root{
    background: #fff;
    border: 1px solid #E2E1E5;
    padding: 6px 9px;
}
.adddriverbox .MuiOutlinedInput-root {
    padding: 0;
}
fieldset{
    border: unset !important;
}
.muiltr-suxku1-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
    font-size: 17px;
}
.muiltr-suxku1-MuiButtonBase-root-MuiChip-root {
    height: 28px;
}
input {
    padding: 6px 0;
}
.cardtoggle label {
    margin: 0;
    display: flex;
}
.cardtoggle label span {
    padding-left: 0;  
    font-size: 14px;
}
.cardtoggle span {
    font-weight: 400;
    font-size: 14px;
}
.cardtoggle {
    border-top: 1px solid #858181;
    padding-top: 18px;
    margin-top: 30px;
    padding-bottom: 12px;
}
button svg {
    fill: #000;
    color: #000;
}
.cardtoggle h3 {
    margin: 0;
    font-size: 13px;
    font-style: italic;
    line-height: 24px;
}
.noteicon {
    border: 1px solid #000;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    padding-top: 5px;
    margin: 7px 0 0;
}
.note-text p {
    width: calc(100% - 30px);
}
.noteicon svg {
    margin: 0 auto;
}
.note-text p {
    width: calc(100% - 30px);
    padding-left: 10px;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
}
.operatoroutter {
    padding: 80px 0 44px;
}
operatoroutter h2 {
    font-size: 30px;
    font-weight: 700;
    margin: 0 0 14px;
}
.operatoroutter p {
    font-size: 20px;
    font-weight: 400;
    max-width: 839px;
    line-height: 34px;
}
.operator-tabel td {
    border: 1px solid #858181;
}
.operator-tabel td, .operator-tabel th {
    font-size: 19px;
    font-weight: 400;
    text-align: center;
    padding: 12px 50px;
}
.operator-tabel td:nth-child(1), .operator-tabel th:nth-child(1){
    text-align: left;
    padding-left:10px;
}
.operator-tabel td:nth-child(1){
    border-left:unset
}
.operator-tabel td:nth-child(4){
    border-right:unset;
}
.operatoroutter h2 {
    font-size: 31px;
    font-weight: 700;
    margin: 0 0 16px;
}
.operator-tabel {
    padding: 25px 0 0;
}
.addrates-names h2 {
    font-size: 20px;
    font-weight: 700;
}

.addrates-names {
    margin: 0 0 30px;
}
.owner-operator {
    max-width: 820px;
    justify-content: space-between;
    flex-wrap: wrap;
}
.owner-operators-value {
    width: 36%;
}
.ownerselect {
    width: 47%;
}
.ownerselect div .MuiSelect-select {
    padding: 8px;
}
.addrates .MuiTypography-root {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #000 !important;
    font-family: 'Poppins', sans-serif !important;
}
.addrates label {
    position: unset !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #000 !important;
    font-family: 'Poppins', sans-serif !important;
    transform: unset !important;
    translate: unset;
    margin: 0 0 7px;
}
.saveoutter h2 {
    font-size: 18px;
    font-weight: 700;
}
.saveoutter {
    align-items: center;
}
.cardoutter {
    margin: 32px 0 0;
}
.backjobs {
    margin: 0 11px 0px;
}
.FusePageCarded-contentWrapper {
    padding: 50px 3.2rem 50px !important;
}
.saveoutter {
    margin: 32px 0 0;
}
.addrates {
    width: 70%;
}
.new-job-btn button {
    background: #EECB33;
    color: #000 !important;
    font-size: 18px;
    text-decoration: unset !important;
    padding: 11px 50px;
    margin-left: 17px;
}
.bottombtns {
    margin: -48px 0 0;
}


.disable-job {
    width: 25%;
    display: flex;
    align-items: flex-start;
}
.select-coustmer {
    width: 70%;
    padding-left: 5px !important;
}
.purchase-oder {
    width: 50%;
}
.custom-textfeild {
    width: 50% !important;
    margin: 0 !important;
}
.disable-job span {
    padding: 0 0 0 12px;
}
.notification-inner {
    width: 29%;
}
.job-edit-form-btns.custom-update-btn {
    top: 52px;
    right: 14px;
}
.addjobsec .MuiContainer-root.MuiContainer-maxWidthXl {
    padding: 0 !important;
}

.add-remobe-btn {
    display: flex;
}

.massage-type div#mui-component-select-type {
    padding: 5px 0;
}
.customsend-massage label {
    top: -4px;
}


@media(max-width:1400px){
    .cardinner h2 {
        font-size: 22px;
        line-height: 31px;
    }
    .jobheading {
        font-size: 46px;
        line-height: 68px;
    }
    .job-billing {
        font-size: 22px;
    }
    .note-text p {
        font-size: 14px;
        line-height: 24px;
    }
    .cardtoggle span {
        font-size: 14px;
    }
    .noteicon svg {
         width: 14px;
        height: 14px;
    }
    .noteicon {
        width: 25px;
        height: 25px;
    }
    .cardinner label {
        font-size: 14px !important;
    }
    .operatoroutter h2 {
        font-size: 28px;
        margin: 0 0 6px;
    }
    .operatoroutter p {
        font-size: 18px;
        line-height: 30px;
    }
    .operator-tabel td, .operator-tabel th {
        font-size: 17px;
    }
    .operator-tabel td svg {
        height: 22px;
        width: 22px;
    }
    .new-job-btn button {
        font-size: 14px;
        padding: 11px 30px;
    }
    .cardinner {
        padding: 25px 25px;
    }
    .location-btn .MuiInputAdornment-root.MuiInputAdornment-positionStart button svg {
        height: 17px;
        width: 17px;
    }
    .location-btn .MuiInputAdornment-root.MuiInputAdornment-positionStart button {
        width: 30px;
        height: 30px;
        line-height: 0;
        min-height: unset;
    }
}

@media(max-width:1366px){
    .destination-right{
        width:100% !important;
    }
}


@media(max-width:1024px){
    .operatoroutter p {
        font-size: 17px;
        line-height: 28px;
    }
    .right.buttons {
        justify-content: flex-start;
        margin: 70px 0 0;
    }
    .addrates {
        width: 100%;
    }
}

@media(max-width:815px){
    .cardinner h3 {
        font-size: 14px;
    }
    .cardinner h2 {
        font-size: 18px;
        line-height: 26px;
    }
    .cardinner h3 {
         margin: 0 0 20px;
    }
    .operatoroutter h2 {
        font-size: 26px;
    }
    .operatoroutter p {
        font-size: 16px;
        line-height: 26px;
    }
    .operator-tabel td, .operator-tabel th {
        font-size: 15px;
    }
}

@media(max-width:800px){
    .notification-content-inner .MuiFormControlLabel-labelPlacementEnd {
        width: 39% !important;
    }
    .notification-inner {
        width: 33% !important;
    }
}


@media(max-width:767px){
    .jobheading {
        font-size: 40px !important;
        line-height: 50px;
    }
    .job-billing {
        font-size: 18px;
    }
    .cardinner {
        width: 100%;
        margin: 0 0 15px;
    }
    .cardinner h2 {
        font-size: 20px !important;
        line-height: 28px !important;
    }
    .operatoroutter {
        padding: 30px 0 44px;
    }
    .addrates-names {
        align-items: center;
    }
    .owner-operators-value input {
        max-height: unset;
        height: unset;
    }
    .saveoutter{
        width: 20%;
        justify-content: flex-end;
    }
    .owner-operators-value {
        width: 34%;
    }
    .ownerselect {
        width: 44%;
    }
    .operatoroutter h2 {
        font-size: 23px;
    }
    .operator-tabel td, .operator-tabel th {
        padding: 12px 20px;
    }
    .operator-tabel table{
        width:100%;
    }
    .newjob-form .destination-right .location-btn {
        top: 24px;
        right: -28px;
    }

.notification-content .notification-content-inner {
    align-items: unset !important;
}
.notification-inner {
    width: 100% !important;
}
.notification-content-inner .MuiFormControlLabel-labelPlacementEnd {
    width: 100% !important;
}
}

@media(max-width:600px){
    .owner-operators-value {
        width: 74%;
        margin: 14px 0 0;
    }
    .ownerselect {
    width: 100%;
}
.saveoutter {
    margin: 12px 0 20px;
}
.owner-operator {
    flex-direction: row;
}
.operator-tabel {
    width: 100%;
    overflow: auto;
}
.job-edit-form-btns.custom-update-btn {
    margin-bottom: 30px;
}
}

@media(max-width:580px){
    .newjob-form .purchase-oder {
        padding: 0 !important;
    }
    .newjob-form .purchase-oder, .custom-textfeild {
        width: 100% !important;
    }
    .select-coustmer {
        width: 100%;
        padding-left: 0 !important;
    }
    .newjob-form .disable-job {
        width: 100%;
    }
    .newjob-form .disable-job span {
        padding: 0 11px 0 0!important;
    }
    .destination-right {
        width: 100% !important;
        padding: 0;
    }
    .location-btn .MuiInputAdornment-root.MuiInputAdornment-positionStart button {
        width: 25px;
        height: 25px;
    }
    .location-btn .MuiInputAdornment-root.MuiInputAdornment-positionStart button svg {
        height: 13px;
        width: 13px;
    }
    .newjob-form .destination-right .location-btn {
        right: 5px;
    }
    .fullwidth{
        width:100%;
    }
    .custom-textfeild {
        padding-left: 0;
    }
    .fullwidth {
        padding: 0;
        margin-bottom: 14px;
    }
}


@media(max-width:480px){
    .owner-operators-value {
        width: 100%;
    }
    .saveoutter {
        justify-content: unset;
    }
    
}