@keyframes glow {
    0% {
      box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
    }
    50% {
      box-shadow: 0 0 40px rgba(255, 255, 255, 1);
    }
    100% {
      box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
    }
  }
  
  .glow {
    animation: glow 1s ease-in-out;
  }