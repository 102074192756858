input:focus-visible,select:focus-visible,textarea:focus-visible {outline: 1px;}
.upload-image {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 8px;
    border-radius: 4px;
}
.flex-direction {
    flex-direction: column;
}
.filter-image{
    filter:invert(1);
}
.popup-image .muiltr-3kgl8r {
    border-radius: 10px;
    margin: 10px;
    border: 10px dashed lightblue;
    display: flex;
    width: 306px;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0;
}
.popup-image .muiltr-3kgl8r .flex {
    padding-bottom: 0;
    flex-direction: column;
    align-items: center;
}
.popup-image .muiltr-3kgl8r .flex img {
    width: 60px;
}
.file-format {
    display: flex;
    gap: 4px;

}