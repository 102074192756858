/**
 * Normalize.css and sTailwind's base styles.
 */

 @import-normalize;

 @import 'tailwindcss/base';
 /* @import "@repay/react-credit-card/dist/react-credit-card.css"; */
 /**
  * Custom base styles, applied after the tailwind-base classes
  */
 
  /**font-size menu**/
 html {
     font-size: 62.5%;
     font-family: 'poppins';
     background-color: #000000;
     font-family: 'poppins';
     background-color: #FFFFFF;
     -webkit-font-smoothing: antialiased;
     text-size-adjust: 100%;
 }
 
 body {
     font-size: 13px;
     line-height: 1.4;
     overflow-x: hidden;
 }
 
 html, body, #root {
     position: relative;
     margin: 0;
     min-height: 100vh;
 }
 
 h1, .h1 {
     font-size: 24px;
 }
 
 h2, .h2 {
     font-size: 20px;
 }
 
 h3, .h3 {
     font-size: 16px;
 }
 
 h4, .h4 {
     font-size: 15px;
 }
 
 h5, .h5 {
     font-size: 13px;
 }
 
 h6, .h6 {
     font-size: 12px;
 }
 
 .ps > .ps__rail-y,
 .ps > .ps__rail-x {
     z-index: 99;
 }
 
 a[role=button] {
     text-decoration: none;
 }
 
 [role="tooltip"] {
     z-index: 9999;
 }
 
 /* Medium Devices, Desktops Only */
 @media only screen and (min-width: 992px) {
     ::-webkit-scrollbar {
         width: 8px;
         height: 8px;
         background-color: rgba(0, 0, 0, 0);
     }
 
     ::-webkit-scrollbar:hover {
         width: 8px;
         height: 8px;
         background-color: rgba(0, 0, 0, 0.06);
     }
 
     ::-webkit-scrollbar-thumb {
         border: 2px solid transparent;
         border-radius: 20px;
     }
 
     ::-webkit-scrollbar-thumb:active {
         border-radius: 20px;
     }
 }
 
 form label {
     z-index: 99;
 }
 
 body.no-animate *,
 body.no-animate *::before,
 body.no-animate *::after {
     transition: none !important;
     animation: none !important;
 }
 
 button:focus {
     outline: none;
 }
 
 /* Removes webkit's autofill backgorund color */
 input:-webkit-autofill,
 input:-webkit-autofill:hover,
 input:-webkit-autofill:focus,
 input:-webkit-autofill:active
 {
     transitionDelay: 9999s;
     transitionProperty: background-color, color;
 }
 
 :focus {
     outline-color: transparent;
 }
 
 /*fullcalendar Fix*/
 .fc-scrollgrid-section-liquid {
     height: 1px !important;
 }
 
 
 .loadz-btn {display: inline-flex;
     align-items: center;
     justify-content: center;
     position: relative;
     box-sizing: border-box;
     -webkit-tap-highlight-color: transparent;
     background-color: transparent;
     outline: 0;
     border: 0;
     margin: 0;
     border-radius: 0;
     padding: 0;
     cursor: pointer;
     user-select: none;
     vertical-align: middle;
     -moz-appearance: none;
     -webkit-appearance: none;
     text-decoration: none;
     color: inherit;
     font-family: poppins;
     font-weight: 100;
     font-size: 1.3rem;
     line-height: 1.75;
     text-transform: uppercase;
     min-width: 64px;
     padding: 6px 16px;
     border-radius: 4px;
     transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
     color: #fff !important;
     background-color: #000000;
     box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
     text-transform: none;
     border-radius: 18px;
     box-shadow: none;
     text-decoration: unset !important;}
 
 .loadz-btn:hover {background: #EDCA33;}
 
 .mui__form-input .MuiInputLabel-root {background: #fff;padding: 0 6px;border-radius: 5px;}
 .mui__form-input .MuiFormControl-root {border-radius: 5px;}
 
 .apexcharts-legend.apexcharts-align-center.apx-legend-position-bottom {
     padding-bottom: 10px;
 }
 .apexcharts-svg.apexcharts-zoomable.hovering-zoom {
     overflow: visible;
 }
 foreignObject {
     height: 357px;
 }
 
 
 /* ALL PAGE CSS 9/8/22 11:55 AM  */
 
 .web-view .MuiDialog-paper.MuiDialog-paperScrollBody {
     max-width: 866px;
 }
 .details-in {
     padding: 0 3px;
 }
 form.mobilefileds-form {
     padding-top: 15px;
 }
 .mobilefileds-form .muiltr-q8hpuo-MuiFormControl-root {
     margin-top: 10px !important;
     padding-top: 0;
 }
 .badge-header {
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 15px;
     border: 1px solid #ccc;
     background: #f5f5f5;
     border-radius: 5px;
     padding-right: 25px;
 }
 .badge-header p .MuiBadge-badge {
     background-color: #e9c917;
     height: 30px;
     width: 30px;
     border-radius: 100px;
     font-size: 15px;
 }
 .list-v .rounded-20 {
     overflow: hidden;
     margin-top: 15px;
 }
 .list-v .rounded-20 .MuiButtonBase-root.MuiListItem-root {
     padding: 15px;
 }
 .p-10.list-v {
     padding: 0;
 }
 .muiltr-fex2b4-MuiPaper-root-MuiCard-root {
     padding-left: 0;
 }
 .badge-header p.MuiTypography-root {
     font-size: 18px;
 }
 button.requestinformation-go-btn {
     max-width: 200px;
 }
 button.requestinformation-accept,
 button.requestinformation-deny {
     width: 100%;
     max-width: 200px;
     min-width: 140px;
     font-size: 16px;
     border-radius: 40px;
 }
 .requestinformation .MuiCard-root {
     width: 100%;
 }
 .requestinformation header h6 {
     font-size: 22px;
 }
 .requestinformation {
     align-items: start !important;
     padding: 15px !important;
 }
 .list-v .rounded-20 .MuiButtonBase-root.MuiListItem-root p {
     font-size: 14px;
 }
 .requestinformation p.MuiTypography-root {
     font-size: 16px;
 }
 .truck-buttons {
     display: flex;
     justify-content: center;
     margin-bottom: 14px;
     gap: 20px;
 }
 .desktop-none {
     display: none;
 }
 
 
 
 /* label[data-shrink=false]+.MuiInputBase-formControl .muiltr-3sosnl-MuiInputBase-input-MuiOutlinedInput-input {
     padding: 0px !important;
 } */
 .MuiAccordionDetails-root.mytablestyle .muiltr-1ynyhby th {
     padding: 10px;
 }
 .new-job-btn.mobilebtn {
     display: none;
 }
 .tickets-btns {
     display: flex;
 }
 .MuiGrid-item.grid-full {
     flex-basis: 100% !important;
     max-width: 100% !important;
 }
 .imgbox.printsizelogo img {
     height: auto !important;
     width: auto;
 }
 .imgbox.printsizelogo {
     display: flex;
     justify-content: center;
     align-items: center;
 }
 /*------------------------------------------------------ UnInvoiced Details ------------------------------------------------------*/
 .main-uninvoiced-details .custom-uninvoiced{
     display: inline-block;
     flex: none;
 }
 .custom-heading {
     font-style: normal;
     font-weight: 700;
     font-size: 30px;
     line-height: 40px;
     letter-spacing: -0.015em;
     color: #000000;
 }
 .new-uninvoiced-details {
     background: #F9F9F9;
     border: 1px solid #000000;
     padding: 30px 40px;
 }
 .new-uninvoiced-details .user-detail,
 .user-price {
     display: flex;
     flex-direction: column;
     gap: 10px;
 }
 .new-uninvoiced-details .invoice-main-title {
     font-size: 16px;
 }
 .new-uninvoiced-details .cst_user {
     font-style: normal;
     letter-spacing: 0.015em;
     color: #000000;
 }
 .new-uninvoiced-details .icon-title {
     font-weight: 600;
     font-size: 12px;
     line-height: 24px;
     letter-spacing: -0.015em;
     color: #000000;
 }
 .new-invoice-table .top-head {
     font-weight: 400;
     font-size: 16px;
     line-height: 36px;
     text-align: center;
     letter-spacing: -0.015em;
     color: #000000;
     padding: 28px 0 25px 0;
 }
 .new-invoice-table .top-data p {
     font-weight: 400;
     font-size: 18px;
     line-height: 18px;
     text-align: center;
     letter-spacing: -0.015em;
     color: #000000;
     padding: 28px 0 25px 0;
 }
 .new-invoice-table .job__data p {
     font-weight: 700;
 }
 .new-invoice-table tbody tr.text-center {
     border-top: 2px solid #000;
 }
 .p-10.list-v.n-list {
     padding: 10px;
     background: #f4f4f4;
     margin-bottom: 10px;
     border-radius: 5px;
     border-bottom: 1px solid #ccc;
     padding-top: 10px;
 }
 span.req-job-id {
     margin: 0;
     display: block;
     padding: 10px;
     background: #000;
     color: #fff;
 }
 span.drfname-drlname {
     padding-left: 0px;
 }
 .n-list .MuiListItem-button {
     background: #fff;
 }
 .main-uninvoiced-details .custom-uninvoiced {
     overflow: auto;
     padding-bottom: 50px;
     height: 100%;
 }
 .cst__table.new-invoice-table .cstm-table-down .ui-table-down {
     font-size: 12px;
     font-weight: 700;
     border-bottom: 0;
 }
 .cst__table.new-invoice-table .cstm-table-down .MuiImageListItem-root {
     list-style: none;
     display: block;
     width: 20px;
     height: 100%;
 }
 .cst__table.new-invoice-table .muiltr-1xr52oh-MuiPaper-root-MuiTableContainer-root {
     border-radius: 0;
     width: 100%;
 }
 .cst__table.new-invoice-table .muiltr-m2olxk-MuiTableRow-root {
     display: inline-table;
     width: 100%;
 }
 
 .cst__table.new-invoice-table .cst-table-data {
     border-bottom: none;
 }
 
 /*------------------------------------------------------ CUSTOMER DETAILS ------------------------------------------------------*/
 .customer-detail-main {
     padding-bottom: 50px;
     height: 100%;
 }
 .customer-detail-main .ChatApp-content {
     display: block;
 }
 .custom-customer-detail .muiltr-3cyr95-MuiPaper-root-MuiAccordion-root:before {
     display: none;
 }
 .lodaz-icon span {
     text-align: center;
 }
 .lodaz-icon span button {
     padding: 0;
     background: transparent !important;
 }
 .lodaz-icon .MuiListItemText-root {
     padding-bottom: 0;
     margin: 0;
 }
 .lodaz-icon span button svg {
     fill: #000;
 }
 .cst__table.new-invoice-table td.top-data:nth-child(2) p {
     font-size: 25px;
 }
 .cst__table.new-invoice-table td.top-data p {
     padding: 15px 0;
 }
 .cst__table.new-invoice-table .MuiAccordionSummary-content {
     margin: 0;
 }
 .cst__table.new-invoice-table .cst-table-data {
     border-bottom: none;
     width: auto;
     padding: 10px;
     display: table-cell;
 }
 .cst__table.new-invoice-table .cst-table-data:nth-child(6), .cst__table.new-invoice-table .cst-table-data:nth-child(7) {
     width: auto;
 }
 .cst__table.new-invoice-table .cst-table-data:nth-child(8), .cst__table.new-invoice-table .cst-table-data:nth-child(9), .cst__table.new-invoice-table .cst-table-data:nth-child(10) {
     width: 11%;
 }
 .custom-uninvoiced table thead {
     border-bottom: 1px solid #000;
 }
 .cst__table.new-invoice-table td.MuiTableCell-root {
     padding: 10px 5px;
 }
 .aging td {
     width: 100%;
     display: inline-block;
     border: 0;
     text-align: center;
 }
 .customer-new-detail.new-invoice th.MuiTableCell-root {
     padding: 10px 5px;
 }
 .newinvoice-ui table tr th {
     padding: 5px 5px;
     border: 0;
 }
 .newinvoice-ui table tr {
     border-bottom: 1px solid #222;
 }
 .new-invoice-bottmtable {
     margin: 0;
 }
 .new-invoice-bottmleft {
     border: 1px solid #000;
     padding: 10px !important;
     margin: 0 !important;
 }
 .new-invoice-bottmright {
     padding: 0 !important;
 }
 .new-invoice-bottmright td {
     border: 0;
     padding: 10px;
 }
 .new-invoice-bottmright tr {
     border-bottom: 1px solid #000;
 }
 .new-invoice-bottmright tr:last-child {
     border-bottom: 0;
 }
 .new-invoice-bottmtable {
     padding-right: 20px;
 }
 .new-invoice-bottmtable table {
     display: grid;
 }
 .newinvoice-ui table tr td {
     font-size: 18px;
 }
 .newinvoice-ui table tr th {
     font-size: 18px;
 }
 .new-invoice-bottmright td {
     font-size: 18px;
 }
 .topbatchno p {
     font-size: 18px;
 }
 table.MuiTable-root.customer-balance-table > thead > tr > th {
     width: 115px;
     display: inline-block;
 }
 table.MuiTable-root.customer-balance-table .MuiAccordionSummary-content {
     width: 100%;
     display: inline-block;
 }
 table.MuiTable-root.customer-balance-table  .MuiAccordionSummary-root {
     padding: 0;
 }
 table.MuiTable-root.customer-balance-table .MuiAccordionSummary-content th, table.MuiTable-root.customer-balance-table .MuiAccordionSummary-content td {
     width: 115px;
     display: inline-block;
 }
 table.MuiTable-root.customer-balance-table .MuiAccordion-root.MuiAccordion-rounded.Mui-expanded {
     background: #f1f1f12e;
     padding-right: 0;
 }
 .customeraccordion .MuiAccordionSummary-content {
     margin: 0;
 }
 table.MuiTable-root.customer-balance-table .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper {
     margin-right: 10px;
 }
 table.MuiTable-root.customer-balance-table  .MuiAccordionSummary-root {
     padding: 0;
     border-bottom: 1px solid #ccc;
     background: #e1e1e1;
 }
 table.customer-table tbody tr {
     border-bottom: 1px solid #cccccc85;
 }
 table.customer-table tbody tr:last-child {
     border-bottom: 0px;
 }
 .customeraccordion {
     margin-bottom: 5px;
     box-shadow: none;
 }
 .customeraccordion:last-child {
     margin-bottom: 0;
 }
 
 .customer-balance .MuiPaper-rounded {
     border-radius: 0;
     box-shadow: none;
     border: 1px solid #ccc6;
 }
 table.MuiTable-root.customer-balance-table > thead > tr > th {
     font-size: 15px;
     border: 0;
 }
 .grandtotal {
     display: flex;
     justify-content: space-between;
     padding: 10px 18px;
     padding-right: 50px;
     font-weight: 600;
     font-size: 15px;
 }
 /* .truncate {
     color: #000;
     font-weight: 600;
 } */
 .clientpage .MuiAvatar-circular {
     background: #D9D9D9;
     color: #000;
     font-weight: 700;
 }
 .cst__table.new-invoice-table td.MuiTableCell-root {
     border: 0;
 }
 .cst__table.new-invoice-table .cst-table-data:nth-child(1) {
     padding: 20px;
     width: 50px;
 }
 .cst__table.new-invoice-table td.MuiTableCell-root img.MuiImageListItem-img {
     cursor: pointer;
 }
 .lodaz-icon {
     cursor: pointer;
 }
 .truncate span {
     color: #000;
     margin-right: 5px;
 }
 .clientpage .MuiButtonBase-root.MuiListItem-root {
     border-radius: 0 !important;
     --tw-shadow: none !important;
     box-shadow: none !important;
     margin-bottom: 0 !important;
     padding: 0px 0px !important;
     min-height: 70px !important;
     border-bottom: 1px solid #000;
 }
 .clientpage .MuiButtonBase-root.MuiListItem-root {
     border-bottom: 1px solid #000;
 }
 .new-invoice-bottmtable p {
     font-size: 16px;
 }
 .newjobdesign .firstsec {
     background: none;
     border: 0;
     box-shadow: none;
     padding: 0;
 }
 .newjobdesign .firstsec .topjobcard {
     padding: 0;
 }
 .job-search-by-date input,
 .job-title-left .job-status input {
     height: 16px;
     padding: 10px;
     background-color: #FFFF;
 }
 .job-search-by-date button.MuiButton-root,
 .job-title-left .job-status button.MuiButton-root {
     margin-left: 10px;
     border-radius: 2px;
     border: 1px solid #000;
     background: #000;
     color: #fff;
 }
 .job-search-by-date {
     padding-left: 20px;
 }
 .job-search-by-date p {
     margin-left: 64px;
 }
 .job-search-by-date > div > span {
     margin: 0 15px;
 }
 .job-right .job-srach {
     margin-right: 15px;
 }
 
 .job-right .job-srach .MuiPaper-rounded {
     border-radius: 2px;
     border: 1px solid #000;
 }
 .job-right .job-add a.MuiButton-root {
     border-radius: 2px;
     font-weight: 600;
 }
 /* .newjobdesign .responsive-loads-table th {
     text-align: center;
 } */
 .newjobdesign .responsive-loads-table tr:nth-child(2n) {
     background: #F5F5F5;
 }
 .newjobdesign .responsive-loads-table th:nth-child(2) b {
     font-size: 25px !important;
     font-weight: 600;
 }
 .newjobdesign .responsive-loads-table th svg, .newjobdesign .responsive-loads-table td svg {
     fill: #000;
 }
 .mobile-p.newjobdesign .jobtablecard {
     border: 1px solid #000;
 }
 .bg-none {
     background: none;
 }
 .newjobdesign .firstsec .topjobcard {
     padding-top: 50px;
     position: relative;
 }
 .newjobdesign .firstsec .topjobcard .job-status p {
     font-size: 30px;
     position: absolute;
     top: 0;
     left: 0;
     font-weight: 600;
 }
 .newjobdesign .firstsec .topjobcard .job-search-by-date p {
     position: absolute;
     top: 22px;
     font-size: 15px;
 }
 .newjobdesign .firstsec .topjobcard .job-status .status-input p {
     font-size: 12px;
     font-weight: normal;
     color: red;
     top: 34px;
 }
 .newjobdesign .firstsec {
     border-radius: 0;
 }
 .job-search-by-date > div > form > span {
     margin: 0 10px;
 }
 .job-title-left .job-status form .MuiInputBase-formControl,
 .job-search-by-date > div > form .MuiInputBase-formControl {
     border: 1px solid;
     border-radius: 0;
 }
 .destination-right {
     position: relative;
 }
 .destination-right .location-btn {
     position: absolute;
     top: 47px;
     right: 2px;
     margin: 0;
 }
 .destination-right .location-btn button {
     box-shadow: none;
 }
 .newjob-map .w-full .h-512 {
     height: 434px;
     margin-top: 22px;
 }
 .FusePageCarded-contentCard.inner-scroll.addnewjobpage {
     border-radius: 0;
     background: transparent;
     box-shadow: none;
 }
 .notification-content-inner .MuiOutlinedInput-root,
 .newjob-form .MuiOutlinedInput-root {
     background: #fff;
     border: 0;
 }
 .newjob-form .MuiFormControl-fullWidth {
     width: 100%;
 }
 .notification-content-inner label,
 .newjob-form label {
     color: #000;
 }
 .notification-content-inner .MuiOutlinedInput-root fieldset,
 .newjob-form .MuiOutlinedInput-root fieldset {
     border-color: #E2E1E5 !important;
 }
 .addjobsec .job-edit-form-btns {
     position: relative;
     right: -6px;
     top: 80px;
 }
 .addjobsec .notification-content button {
     box-shadow: none;
 }
 .cst__table.new-invoice-table.customnew-heading > table {
     table-layout: fixed;
 }
 .cst__table.new-invoice-table.customnew-heading > table th.top-head {
     width: 140px;
     font-size: 14px;
 }
 .cst__table.new-invoice-table.customnew-heading .MuiAccordion-root table {
     table-layout: fixed;
 }
 .cst__table.new-invoice-table.customnew-heading .MuiAccordion-root table td.top-data {
     width: 140px;
 }
 .cst__table.new-invoice-table.customnew-heading .MuiAccordion-root table .MuiAccordionSummary-gutters {
     padding: 0;
 }
 .cst__table.new-invoice-table.customnew-heading .MuiAccordion-root table td:last-child {
     width: 24px;
 }
 .cst__table.new-invoice-table.customnew-heading .MuiAccordion-root table .MuiAccordionSummary-gutters tr.text-center {
     border: 0;
 }
 .jobgraph {
     border: 2px solid #000;
     margin-top: 20px;
     margin-left: 40px;
     margin-bottom: 20px;
 }
 .jobgraph .apexcharts-toolbar {
     display: none;
 }
 .jobTprogress {
     display: inline-block;
 }
 .job-notes-btn button {
     border: 1px solid #000;
     color: #000;
     border-radius: 0;
     font-size: 15px;
     padding: 5px 46px;
 }
 .jobgraph .apexcharts-legend.apexcharts-align-center.apx-legend-position-bottom {
     padding-bottom: 55px;
 }
 .newlayoutjotticket button.MuiButtonBase-root {
     padding: 0;
     margin: 0 !important;
     border: 0 !important;
     border-radius: 0 !important;
     margin-right: 10px !important;
     background-color: #D4D6D9!important;
     font-weight: 400 !important;
     text-transform: capitalize !important;
     border-top-left-radius: 10px !important;
     border-top-right-radius: 10px !important;
 }
 .newlayoutjotticket button.MuiButtonBase-root.Mui-selected {
     background-color: #414042 !important;
 }
 .newlayoutjotticket button.MuiButtonBase-root:hover {
     color: inherit !important;
 }
 .newlayoutjotticket button.MuiButtonBase-root.Mui-selected:hover {
     color: #fff !important;
 }
 .newlayoutjotticket .jobticket-serch-nd-btns button.MuiButton-root {
     padding: 15px;
     height: 68px;
     border-radius: 10px !important;
     width: 170px;
     margin: 0 !important;
     font-weight: 600 !important;
     background: #e9c917 !important;
 }
 .newlayoutjotticket .jobticket-serch-nd-btns .MuiPaper-rounded {
     box-shadow: none;
     height: 68px;
     border-radius: 10px;
     margin-right: 10px;
     border: 1px solid #000;
     padding: 0 15px;
 }
 .newlayoutjotticket .jobticket-serch-nd-btns .MuiPaper-rounded span.material-icons {
     font-size: 40px;
 }
 .newlayoutjotticket .jobticket-serch-nd-btns .MuiPaper-rounded input::placeholder {
     font-size: 18px;
     color: #000 !important;
 }
 .newlayoutjotticket .jobticket-serch-nd-btns .MuiPaper-rounded input {
     line-height: 55px;
     padding-top: 10px;
     color: #000;
 }


 .newlayoutsubscriptions button.MuiButtonBase-root {
    padding: 0;
    margin: 0 !important;
    border: 0 !important;
    border-radius: 0 !important;
    margin-right: 10px !important;
    background-color: #414042!important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
}
.newlayoutsubscriptions button.MuiButtonBase-root.Mui-selected {
    background-color: #e9c917 !important;
    color: #FFFFFF !important;
}
/* 414042
e9c917 */
.newlayoutsubscriptions button.MuiButtonBase-root:hover {
    color: inherit !important;
}




 .new-truck-layout {
     background: #fff;
     padding: 15px 20px !important;
     border-radius: 10px;
 }
 .new-truck-layout p.truck-no {
     font-size: 22px;
     font-weight: 700;
     margin-bottom: 15px;
 }
 .new-truck-container {
     margin: 0;
 }
 .new-truck-layout img {
     background-color: #f4f4f4;
     border-radius: 10px;
 }
 .new-truck-layout a {
     width: 100%;
     padding: 10px;
     border: 1px solid #000;
     border-radius: 10px;
     font-size: 14px;
     margin-bottom: 10px;
     height: 50px;
     font-weight: 700;
     background: transparent;
 }
 .truck-value-text {
     font-size: 16px;
     line-height: 30px;
 }
 .new-truck-container {
     margin: 0;
     display: grid;
     grid-template-columns: 50% 50%;
     grid-gap: 30px;
     justify-content: center;
     margin: 0 auto;
     margin-top: 30px;
 }
 .new-truck-sidebarcontent a.MuiButton-root,
 .new-truck-sidebarcontent button.MuiButton-root {
     padding: 10px 40px;
     border-radius: 10px;
     font-size: 14px;
     height: 50px;
     font-weight: 700;
 }
 .new-truck-buttons {
     background: #fff;
     padding: 10px 20px;
     border: 1px solid #000;
     border-radius: 10px;
 }
 .new-truck-main {
     max-width: 1570px;
     margin: 0 auto;
 }
 .truck-add-edit-container {
     max-width: 1570px;
     width: 100%;
     margin: 0 auto;
     padding: 0 15px;
 }
 .truck-add-edit-container .mobilefileds-form {
     padding: 0;
 }
 .add-rid-btns button.MuiButton-root {
     border-radius: 5px;
 }
 .truck-add-edit-container .newjob a.MuiButton-root {
     width: 100%;
     max-width: 115px;
     background: transparent !important;
 }
 .clientpage.driverpage ul .p-10 {
     padding: 0 10px;
 }
 .clientpage.driverpage ul p.MuiTypography-root {
     font-size: 1.4rem;
 }
 .notification-content-inner .MuiOutlinedInput-root, .newjob-form .MuiOutlinedInput-root {
     height: 57px;
 }
 .drivermodal .MuiDrawer-paper.MuiDrawer-paperAnchorRight {
     width: 55% !important;
 }
 .driverform-edit {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: flex-start;
     padding: 80px;
 }
 .driverform-edit {
     width: 100%;
 }
 .driverform-edit {
     display: flex;
     flex-direction: row;
     justify-content: space-around;
     align-items: flex-start;
     padding: 130px;
     padding-top: 60px;
 }
 .driverform-edit-form {
     width: 50%;
 }
 .driver-list-imgs {
     width: 50%;
 }
 .inner-imgs .img-box {
     margin: 0 auto;
 }
 .driverform-edit-form .savebtn {
     justify-content: end;
 }
 .driverform-edit-form .savebtn button {
     background: #000;
     padding: 10px 20px;
     border: 1px solid #000;
     border-radius: 10px;
     width: 200px;
     color: #fff;
     text-transform: uppercase;
     font-size: 18px;
 }
 .inner-imgs {
     padding: 0px 45px;
     width: 100%;
 }
 .new-uninvoiced-details .user-detail .MuiBox-root {
     text-align: left;
     padding: 0;
 }
 .new-uninvoiced-details .user-detail button {
     padding: 0;
     text-align: left;
     justify-content: start;
     font-size: 16px;
     font-weight: 600;
     color: #000;
     text-decoration: underline;
 }
 .driver-list {
     padding: 0;
 }
 .driver-list .MuiTabs-indicator {
     display: none;
 }
 .driver-list button.MuiButtonBase-root {
     font-size: 18px;
     /* color: #000; */
     font-weight: 600;
     padding: 0 20px;
     height: auto;
     border: 1px solid #000;
     margin-left: 15px;
 }
 .driver-list-btns {
     height: auto;
 }
 .dashboard-table td, .dashboard-table th {
     text-align: center;
 }
 .dashboard-table td {
     font-size: 16px;
 }
 /* ALL PAGE RESPONSIVE CSS */
 @media only screen and (max-width : 1440px) and (min-width : 767px){
     .MuiDrawer-paper {
         width: 285px !important;
     }
     .new-inspections-header p {
         font-size: 16px;
     }
     .NewInspectionsApp-content.right-table table tr th {
         padding: 10px;
         text-align: left;
         line-height: 16px;
     }
     .NewInspectionsApp-content.right-table table tr td {
         padding: 10px;
         text-align: left;
         line-height: 16px;
         font-size: 12px;
     }
     .NewInspectionsApp-content.right-table .MuiPaper-elevation {
         padding: 0;
     }
     .NewInspectionsApp-content.right-table table {
         min-width: 100%;
     }
     .mobile-width-full.m-d-screens {
         width: 60%;
     }
     .md-screens .mobile-width-full .w-full {
         width: 100%;
     }
     .MuiGrid-item.top-full-grid {
         flex-basis: 100%;
         flex-grow: 0;
         max-width: 100%;
     }
     .MuiGrid-item.top-full-grid.empty {
         display: none;
     }
     .customer-full-table .responsive-loads-table {
         width: 100% !important;
         padding-left: 15px;
     }
     .customer-full-table .responsive-loads-table .p-16 {
         padding: 10px 0;
     }
     .customer-full-table .responsive-loads-table table tr th {
         font-size: 12px;
         vertical-align: top;
     }
     main.customer-70-table {
         width: 70%;
     }
     main.customer-70-table .ChatApp-content {
         width: 100%;
         display: inline-block;
     }
     .customer-full-table .responsive-loads-table {
         padding-right: 15px;
         /* overflow-y: hidden; */
     }
     .responsive-loads-table .responsive-loads-table table {
         table-layout: auto;
         width: 266%;
         background: #f4f4f4;
     }
     .responsive-loads-table .responsive-loads-table {
         padding: 0;
     }
     main.customer-70-table .ChatApp-content {
         /* overflow: auto; */
     }
     h1, .h1 {
         font-size: 20px;
     }
     td.MuiTableCell-root.MuiTableCell-footer {
         width: 51%;
     }
     .jobstable .responsive-loads-table table {
         table-layout: fixed;
         width: 1535px;
     }
 }

@media only screen and (max-width:1500px){
    .tickets-header {
        flex-wrap: wrap;
    }
    .tickets-header .jobticket-btns {
        width: 42%;
    }
    
    .tickets-header .jobticket-serch-nd-btns {
        width: 58%;
    }
    .newlayoutjotticket .jobticket-serch-nd-btns button.MuiButton-root {
        height: 52px;
        border-radius: 10px !important;
        width: 157px;
    }
}

@media only screen and (max-width:1440px){
    .upload-img-inr {
        width: 100%;
    }
}


 @media only screen and (max-width : 1280px){
     .job-number p.text-72 {
         font-size: 36px;
     }
     .job-number p.text-24 {
         font-size: 16px;
     }
     .jobTprogress span.MuiCircularProgress-root {
         width: 100px !important;
         height: 100px !important;
     }
     .jobTprogress .MuiTypography-caption {
         font-size: 20px;
     }
     .jobticket-btns .MuiTabs-scroller .MuiTabs-flexContainer {
         align-items: center;
     }
     .jobticket-btns .MuiTabs-scroller .MuiTabs-flexContainer button.MuiButtonBase-root {
         font-size: 14px !important;
         width: auto !important;
         padding: 0 20px;
         height: 40px !important;
     }
     .jobticket-btns .MuiTabs-scroller .MuiTabs-flexContainer .jobticket-serch-nd-btns .MuiPaper-root {
         height: 40px;
         border-radius: 3px;
         padding: 0 8px;
     }
     .jobticket-btns .MuiTabs-scroller .MuiTabs-flexContainer .jobticket-serch-nd-btns .MuiPaper-root svg {
         height: 15px;
     }
     .jobticket-btns .MuiTabs-scroller .MuiTabs-flexContainer .tickets-btns button.MuiButton-root {
         padding: 0 10px;
         font-weight: 400 !important;
         font-size: 13px !important;
         border-radius: 3px !important;
     }
    .job-notes-btn button {
        padding: 5px 15px !important;
    }
    .newlayoutjotticket .jobticket-serch-nd-btns button.MuiButton-root {
        width: 152px;
        padding: 15px 8px;
    }
    
 }

@media only screen and (max-width:1100px){
    .fuellog-btns a, .fuellog-btns button {
        margin: 0 3px;
 }
 .fuellog-icon span.material-icons {
    font-size: 18px;
}
.fuellog-icon span.MuiTypography-root {
    font-size: 20px;
    margin: 0 0 0 6px;
}
}

 @media only screen and (max-width : 1024px){
    .invitemobileuserbox .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
        width: 95% !important;
    }
    
    .recouncile-modal .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
        max-width: unset!important;
        width: 95% !important;
    }
     .new-truck-container {
         grid-template-columns: 100%;
     }
     .fuellog-btns a, .fuellog-btns button {
        margin: 0 4px;
    }
   
    .ticket-email-poup .MuiBox-root {
        width: 90% !important;
    }
    .ticket-email-poup .react-multi-email input:empty {
        display: none !important;
    }
 }
 
@media only screen and (max-width:990px){
    .logs-outter {
        width: 100%;
        margin: 10px 0 0;
    }
    .fuel-loglist.fuellog-sm {
        flex-wrap: wrap;
    }
}

@media only screen and (max-width:959px){
    .tickets-header .jobticket-serch-nd-btns {
        width: 100%;
    }
    .tickets-header .jobticket-btns {
        width: 100%;
        margin: 0 0 15px;
    }
    .drivermodal .driver-list-edit .driverform-edit {
        padding: 25px !important;
    }
    .driver-list-edit .driverform-edit {
        flex-wrap: wrap;
    }
    .driverform-edit .driverform-edit-form, .driverform-edit .driver-list-imgs {
        width: 100%;
    }
    .inner-imgs.edit-upload-img {
        padding: 8px 0 !important; 
    }
    .create-customer .edit-customers {
        padding: 17px 20px !important;
    }
    .create-customer h2.MuiTypography-h6 {
        padding: 20px 20px !important;
    }
    .custom-customer-detail button.MuiButton-root.MuiButton-outlined.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root {
        background: #EDCA33;
        border-color: #EDCA33;
    }
}

 @media only screen and (max-width : 900px){
 .onmobiledrawr .MuiDrawer-paper {
     width: 100px !important;
 }
 .mobiletogglebtns button {
     height: 55px;
     width: 55px;
     border: 0;
 }
 .onmobiledrawr .MuiDrawer-paper {
     overflow: visible !important;
 }
 .onmobiledrawr .MuiDrawer-paper .flex {
     overflow: visible;
 }
 .onmobiledrawr .MuiDrawer-paper .MuiPaper-root {
     position: fixed;
     width: 70%;
     right: 100px;
     border-radius: 0 !important;
     box-shadow: none;
     border: 0;
 }
 .onmobiledrawr .MuiDrawer-paper .MuiPaper-root > div {
     display: flex;
 }
 .settingsModel .MuiDrawer-paper {
     width: auto !important;
 }
 .onmobiledrawr .MuiDrawer-paper .MuiFormControl-fullWidth {
     margin: 3px 0;
 }

}
.MuiContainer-maxWidthLg {
    max-width: unset;
    padding: 0;
}
@media only screen and (max-width : 767px){
    .edit-customers .flex.new-customers .mb-16 {
        margin-bottom: 5px;
    }
    .edit-customers .term {
        margin: 7px 0px 22px;
    }
    .edit-customers .website.flex .mb-16{
        margin-bottom: 15px;
    }
    .edit-customers .flex .email.mb-16 {
        margin: 15px 0px;
    }
    .edit-customers .new.flex {
        margin: auto;
        margin-bottom: 20px;
     }
    .payment-modal .MuiCardContent-root {
        padding-left: 10px;
        padding-right: 10px;
    }
    .payment-modal .MuiBox-root {
        margin-left: 0;
        margin-right: 0;
    }
    .ticket-email-poup .MuiBox-root {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 17px;
    }
    .ChatApp-contentCard.shadow.rounded-20.customer-full-table {
        height: 100%;
        overflow: hidden;
    }
    .main-column.main-column1, .main-column.main-column2, .main-column.main-column3 {
        width: 100% !important; 
    }
    .typehaul-modal .mobilefileds-form .MuiDialogContent-root {
        height: unset !important;
    }
    .typehaul-modal .mobilefileds-form .MuiInputBase-formControl {
        padding: 6px 9px;
    }
    .user-img-upload .MuiBox-root {
        margin: 0 auto;
    }
    .customer-full-table {
        flex: unset !important;
    }
    .customer-full-table .customer-detail-main {
        padding-bottom: 10px;
    }
    .customer-detail-main .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.rounded-full {
        padding: 0;
    }
    .new-modal.edit-company-info .p-56 {
        padding: 30px 18px;
    }
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth.muiltr-8k8yl-MuiPaper-root-MuiDialog-paper {
        margin: 15px auto !important;
    }
    .edt-user-profile-popup .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-3{
        width: 100%;
        max-width: unset;
        flex-basis: unset;
        padding: 40px 0 0;
    }
    .edt-user-profile-popup .MuiFormControl-root.MuiTextField-root.mb-24.mobile-w-full.newprofile-p.muiltr-1u3bzj6-MuiFormControl-root-MuiTextField-root {
        margin-bottom: 10px;
    }
    .edt-user-profile-popup .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-9 {
        width: 100%;
        max-width: unset;
        flex-basis: unset;
        order: 2;
    }
    .edt-user-profile-popup .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2 {
        margin-top: 0 !important;
    }
    .edt-user-profile-popup .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6{
        width: 100% !important;
        flex-basis: unset !important;
        max-width: unset;
    }
    .edt-user-profile-popup .min-w-48.pt-20 {
        display: none;
    }
    .edt-user-profile-popup h3.text-lg.font-bold.pl-50 {
        padding-left: 0 !important;
    }
    .create-customer .MuiTypography-h6 {
        margin: 0 0 12px !important;
    }
    .create-customer .new-customers p.MuiFormHelperText-root {
        color: rgb(237, 202, 51);
        margin: 0;
        position: relative;
        bottom: 7px;
    }
    .create-customer .MuiOutlinedInput-root.MuiInputBase-root {
       margin: 0 0 10px;
    }
    .create-customer .MuiPaper-root.MuiPaper-elevation .edit-customers .new-customers input.MuiOutlinedInput-input, .MuiPaper-root.MuiPaper-elevation .edit-customers .addnewjobpage .MuiInputBase-formControl input.MuiOutlinedInput-input {
        padding: 5px !important;
        height: unset !important;
    }
    .term-service-wrapper .submit_btn button {
        width: 100%;
    }
    .payment-outter {
        padding-bottom: 220px !important;
    }
    .term-service-wrapper p.text-left input {
        height: 10px !important;
        width: 10px;
    }
    .term-service-wrapper p.text-left {
        font-size: 12px !important;
    }
    .payment-outter .copyright-text p {
        --tw-text-opacity: 1;
        color: rgba(141, 144, 151, var(--tw-text-opacity));
        border-top: 1px solid #f3f3f3;
        padding-top: 11px;
    }
    .apply-payment-text {
        align-items: center !important;
    }
    .apply-payment-text p {
        font-size: 13px !important;
    }
    .payment-outter .credit-card.tabbing-text {
        padding: 0 12px;
        width: unset;
    }
    
    

    .ecommerce-header.inspection-outter .FusePageCarded-contentCard.inner-scroll.addnewjobpage {
        padding-top: 0;
    }
    .job-edit-form.pb-44 {
        padding-bottom: 30px;
    }
    .job-edit-form .job-edit-form-btns.custom-update-btn {
        top: 10px;
    }
    .job-number.job-number2 p.text-44 {
        font-size: 30px !important;
    }
    .truck-buttons.desktop-none {
         display: flex;
         padding-top: 10px;
         padding-bottom: 10px;
     }
     .truck-buttons.desktop-none.pb-sm-0 {
         padding-bottom: 0;
     }
     .mobile-width-full {
         width: 100%;
     }
     .mobile-flex-col {
         flex-direction: column;
     }
     .mobile-width-full .w-full {
         width: 50%;
     }
     .jobstable .responsive-loads-table {
         padding: 0 30px;
     }
 /* }
 
 @media only screen and (max-width : 880px){ */
     .flex.mx-8.mobile-flex-col {
         flex-direction: column;
     }
     .mobile-width-full {
         width: 100% !important;
     }
     .MuiCardContent-root.dashboardCounters .MuiGrid-container {
         grid-template-columns: repeat(2, 50%);
         grid-gap: 5px;
     }
     .MuiCardContent-root.dashboardCounters .MuiGrid-container .MuiGrid-item .rounded-none {
         height: 155px;
     }
     .dashboardCounters p.MuiTypography-root.text-56 {
         font-size: 36px;
     }
     .muiltr-ypiqx9-MuiDialogContent-root table td {
         width: 100%;
         text-align: left;
         display: inline-block;
     }
     .muiltr-ypiqx9-MuiDialogContent-root table td {
         padding: 5px 0;
         text-align: left;
         border: 0 !important;
     }
     .muiltr-ypiqx9-MuiDialogContent-root table.MuiTable-root {
         border: 0 !important;
     }
     .muiltr-1tz4nvp-MuiPaper-root-MuiDialog-paper {
         margin: 10px;
     }
     .muiltr-hlj6pa-MuiDialogActions-root {
         margin-top: 40px;
     }
     .muiltr-1tz4nvp-MuiPaper-root-MuiDialog-paper {
         max-height: calc(100% - 20px);
     }
     .muiltr-1tz4nvp-MuiPaper-root-MuiDialog-paper {
         max-width: 59% !important;
     }
     .muiltr-11bwafi-MuiTypography-root-MuiDialogTitle-root {
         padding: 0;
     }
     .muiltr-1y5dozm-MuiTypography-root {
         font-size: 16px;
     }
     .muiltr-12mhevi {
         min-width: 30px;
         padding: 6px 0px;
     }
     a.topbar-youtube button {
         min-width: auto;
         padding: 0;
     }
     .mobilefileds .MuiInputBase-formControl {
         padding: 0;
     }
     /* .mobile-truck-list p.MuiTypography-root {
         font-size: 10px;
     } */
     .mobile-truck-list hr {
         margin-top: 5px;
     }
     .mobile-truck-list {
         padding: 0 10px !important;
     }
     .mobile-truck-list > div > div {
         margin-bottom: 10px;
     }
     .mobile-truck-list .MuiListItem-gutters {
         padding: 10px 0;
     }
     form.mobilefileds-form .MuiInputBase-formControl {
         padding: 0;
     }
     form.mobilefileds-form {
         margin-top: 15px;
     }
     .mobilefileds.emailbox textarea {
         height: 100px !important;
     }
     .mobilefileds.emailbox form.mobilefileds-form {
         padding-bottom: 15px;
     }
     .mobilefileds.emailbox .MuiSelect-select {
         padding: 10px !important;
     }
     .mobilefileds.emailbox .MuiToolbar-regular {
         font-size: 22px;
     }
     .mobile-p-0 .h1 {
         font-size: 20px;
         padding: 0;
     }
     .mobile-p-0 {
         padding: 15px 0 !important;
     }
     .mobile-p-0 .muiltr-185gdzj-MuiCardHeader-root {
         padding: 0 16px;
     }
     .mobile-p-0 table {
         width: 100%;
         border-collapse: collapse;
         border-spacing: 0;
         border: 1px solid #ddd;
     }
     .mobile-p-0 table tr {
         border-bottom: 1px solid rgba(204, 204, 204, 0.369);
     }
     .muiltr-13lwv77 .NewInspectionsApp-contentWrapper {
         width: 100%;
     }
     .muiltr-dlzsyi-MuiTable-root {
         min-width: 100%;
     }
     .muiltr-siivb9-MuiPaper-root-MuiDialog-paper.MuiDialog-paperScrollBody {
         max-width: 61.5%;
         margin: 0 auto !important;
         padding: 0 !important;
     }
     body {
         padding-right: 0 !important;
     }
     .muiltr-46v7vj-MuiDialog-container {
         height: auto;
         text-align: left;
         padding: 20px;
     }
     .Invitations-box table {
         table-layout: fixed;
     }
     .Invitations-box table.simple.borderless thead tr th {
         width: 110px;
     }
     .Invitations-box table.simple.borderless thead tr th:nth-child(3) {
         width: 220px;
     }
     .Invitations-box table.simple.borderless tbody tr td {
         width: 110px;
     }
     .Invitations-box table.simple.borderless tbody tr td:nth-child(3) {
         width: 220px;
     }
     .Invitations-box .MuiPaper-elevation.MuiPaper-rounded {
         width: 59%;
         margin: 15px;
     }
     .Invitations-box .MuiPaper-elevation h2 {
         padding: 15px 10px;
     }
     .Invitations-box .MuiDialogContent-root {
         padding: 0 10px;
     }
     .web-view .MuiDialog-paper.MuiDialog-paperScrollBody {
         max-width: 100% !important;
         width: 100%;
         margin: 0 !important;
         padding: 0 !important;
     }
     form.mobilefileds-form {
         padding-top: 0;
         margin-top: 10px;
     }
     .mobilefileds-form .p-0.MuiDialogContent-root.muiltr-ypiqx9-MuiDialogContent-root {
         height: 66vh;
     }
     form.mobilefileds-form .details-in .MuiFormControl-fullWidth {
         width: 100%;
         padding: 3px 0;
     }
     form.mobilefileds-form .details-in {
         padding: 0;
     }
     form.mobilefileds-form .MuiDialogActions-spacing {
         margin: 0;
     }
     .MuiFormControl-root.questions-mobile {
         width: 100%;
         margin: 0px 0 10px 0;
     }
     
     .muiltr-46v7vj-MuiDialog-container .muiltr-siivb9-MuiPaper-root-MuiDialog-paper {
         width: calc(100% - 0px) !important;
     }
     .Truckdetails-questions.mobile {
         margin-top: 0;
     }
     .mobilefileds-form .muiltr-q8hpuo-MuiFormControl-root {
         margin-top: 15px !important;
     }
     .muiltr-cqqn9m .NewInspectionsApp-contentCard {
         overflow: visible;
     }
     .NewInspectionsApp-content.right-table table {
         min-width: 1500px !important;
     }
     .NewInspectionsApp-content.right-table table tr th {
         padding: 20px 10px;
         line-height: 20px;
         font-size: 16px;
     }
     .NewInspectionsApp-content.right-table table tr td {
         padding: 20px 10px;
         line-height: 20px;
         font-size: 16px;
     }
     .new-inspections-header p {
         font-size: 12px;
     }
     .muiltr-cqqn9m .NewInspectionsApp-content {
         width: 366px;
     }
     .new-inspections-header .MuiAvatar-root.MuiAvatar-circular {
         width: 30px;
         height: 30px;
     }
     .NewInspectionsApp-contentWrapper .MuiToolbar-root.MuiToolbar-gutters {
         padding: 0;
     }
     .MuiPaper-elevation0.MuiPopover-paper {
         left: 140px !important;
         top: 58px !important;
     }
     form.mobilefileds-form > div > .mb-16 {
         margin: 0;
     }
     form.mobilefileds-form > div > .mb-16 .p-12 {
         padding: 0;
     }
     .muiltr-46v7vj-MuiDialog-container {
         padding: 10px;
     }
     .mobilefileds.emailbox form.mobilefileds-form .MuiFormControl-fullWidth {
         padding: 0;
         margin: 0;
     }
     .muiltr-buvykw-MuiPaper-root-MuiDialog-paper {
         max-height: calc(65% - 40px) !important;
     }
     button.requestinformation-go-btn {
         max-width: 100%;
         padding: 8px;
         width: 100%;
     }
     .requestinformation p.MuiTypography-root {
         font-size: 14px;
     }
     .requestinformation header h6 {
         font-size: 18px;
     }
     .NewInspectionsApp-contentWrapper header p.MuiTypography-root {
         font-size: 14px;
         line-height: 20px;
     }
     .ajobtable {
         overflow: hidden;
         display: block;
         width: 100%;
         max-width: 310px;
     }
     .mobile-width-full .w-full {
         width: 100%;
     }
     .md-screens .mobile-width-full .w-full {
         width: 100%;
     }
     .mobile-p-0 table tr:first-child td {
         padding: 3px !important;
         text-align: left;
     }
     .trailer-tires textarea[aria-invalid=false] {
         padding: 10px !important;
         min-height: 30px;
     }
     .email-mob-modal.MuiBox-root {
         width: 96%;
         padding: 15px;
     }
     .email-mob-modal h6 {
         margin-top: 30px;
     }
     .email-mob-modal .send-email-btn button[type=button] {
         width: 100%;
         border-radius: 5px;
         font-size: 18px;
     }
     .driver-list-edit .MuiCard-root {
         width: 96%;
         margin: 5px auto;
     }
     .driver-list-edit .MuiCard-root .min-w-48 {
         min-width: 0;
     }
     .card-inner {
         padding: 10px 0 !important;
     }
     .alljobs-headr {
         flex-direction: column!important;
         align-items: start;
     }
     .responsive-loads-table table tr td:before, .responsive-loads-table table tr th:before {
         font-weight: 700;
     }
     .responsive-loads-table table tr td:empty, .responsive-loads-table table tr th:empty {
         display: none;
     }
     .p-24.mobile-p {
         padding: 0 10px;
     }
     .jobstable {
         padding: 0;
     }
     .newprofile .min-w-48 {
         min-width: 0;
     }
     .newprofile .mobile-w-full {
         width: 100%;
         margin-bottom: 15px;
     }
     .newprofile .mobile-w-full .MuiInputBase-root {
         padding: 0;
     }
     .MuiCardContent-root.userform {
         padding: 0;
     }
     .mobile-profile {
         padding-bottom: 0 !important ;
     }
     .shadow.mobile-admin-w-full {
         width: 100%;
     }
     .mobile-admin-profile {
         height: 90px !important;
         width: 90px !important;
     }
     .mobile-admin-profile img {
         height: 85px;
         width: auto;
     }
     .admin-header {
         text-align: left;
         align-items: start !important;
         padding: 10px;
         padding-left: 115px !important;
     }
     .admin-header h5 {
         padding-bottom: 0;
         font-size: 18px;
     }
     .admin-header h6 {
         padding-top: 0;
         font-size: 14px;
     }
     .mobile-pt-0 {
         padding-top: 0 !important;
     }
     .mobile-w-full {
         width: 100% !important;
     }
     .adminimg {
         padding-left: 0;
         padding-right: 0;
         padding-top: 0;
         padding-bottom: 0;
         text-align: center;
         align-items: center;
         display: flex;
         justify-content: center;
         width: 165px;
         height: 165px;
         border-radius: 15px;
     }
     .newprofile-p input {
         padding: 10px !important;
     }
     .admin-img-mobile {
         flex-direction: row !important;
         padding-bottom: 0 !important;
         justify-content: center;
         align-items: center;
         font-size: 12px;
     }
     .mobile-flex-row{
         flex-direction: row !important;
         justify-content: space-around;
     }
     .time-batching-form .flex {
         flex-direction: column;
         margin: 0;
         margin-top: 20px;
     }
     .time-batching-form {
         width: 100%;
         display: flex;
         flex-direction: column;
     }
     .selectdaytobatch {
         margin: 0 0;
         width: 100% !important;
         margin-top: 20px;
     }
     .selectdaytobatch .MuiTextField-root {
         width: 100%;
     }
     .time-batching-modal h2 {
         padding: 10px 30px;
         padding-bottom: 0;
     }
     .mob-selectdaytobatch-btns {
         padding-bottom: 10px;
     }
     /* .NewInspectionsApp-content.right-table table {
         min-width: 100% !important;
     } */
     .dashboard-table td.MuiTableCell-root {
         padding: 5px 0;
     }
     .dashboard-table td.MuiTableCell-root table tr td {
         text-align: right;
     }
     .dashboard-table td.MuiTableCell-root table tr {
         padding: 5px;
         background: #fff;
         margin-bottom: 20px;
         box-shadow: 0px 0px 10px #0000002b;
     }
     .dashboard-table td.MuiTableCell-root table{
         border: 0;
     }
     .dashboard-table td.MuiTableCell-root {
         font-size: 12px;
     }
     .responsive-loads-table table tr td:before, .responsive-loads-table table tr th:before {
         font-size: 12px;
     }
     .dashboard-table td.MuiTableCell-root table tr td svg {
         width: 20px;
         height: 20px;
     }
     .dashboard-table td.MuiTableCell-root table tr td:last-child {
         border-bottom: 0;
     }
     .dashboard-table td.MuiTableCell-root table tr td button {
         margin: 0;
         padding: 5px 0;
     }
     .newjob {
         flex-direction: column;
     }
     .newjob-form {
         width: 100% !important;
     }
     .newjob-map {
         width: 100% !important;
         padding-left: 0 !important;
     }
     .newjob-form .flex.mx-6 {
         width: 100%;
         display: inline-block;
         margin: 0 !important;
         padding: 0 !important;
     }
     .notification-content-inner {
         flex-direction: column;
     }
     .newjob-form input {
         /* padding: 0 !important; */
         height: 30px;
     }
     .location-btn {
         width: 15%;
         float: right;
         padding-top: 23px;
         text-align: right;
     }
     .destination {
         width: 80%;
     }
     .notification-content input {
         height: 30px;
     }
     .MuiFormControl-root.email input, .MuiFormControl-root.phone input {
         padding: 10px;
     }
     .remove-btn {
         margin-top: 40px;
         margin-bottom: 30px;
     }
     .job-section {
         flex-direction: column;
         justify-content: start;
         align-items: start;
     }
     .job-section span.MuiTypography-root {
         display: inline-block;
     }
     .jobs-inner {
         width: 100%;
         padding: 10px 0;
         display: inline-block !important;
     }
     /* .new-job-btn {
         margin-top: -38px;
     } */
     .job-section label {
         padding-left: 0;
         margin: 0;
         margin-top: 5px;
     }
     .new-job-btn.mobilebtn {
         float: right;
         width: auto;
     }
 
     .srch-mobile {
         padding: 0;
     }
     .flex.items-center.jobs-inner span.material-icons {
         position: relative;
         top: 5px;
     }
     .srch-mobile {
         margin-bottom: 20px;
     }
     .new-job-btn.mobilebtn {
         display: inline-block;
     }
     .new-job-btn.desktop {
         display: none;
     }
     .srch-mobile {
         padding-right: 0 !important;
     }
     h4.MuiTypography-root.MuiTypography-h4 {
         font-size: 18px;
     }
     .FusePageCarded-contentCard.inner-scroll {
         border-radius: 10px;
     }
     .newjob-form .MuiOutlinedInput-root {
         padding: 0 10px;
         max-height: 50px;
     }
     .notification-content .MuiOutlinedInput-root {
         padding: 0 10px;
         max-height: 50px;
     }
     .newjob-form .notes .MuiOutlinedInput-root {
         max-height: initial;
         padding: 10px 15px;
     }
     .mt-8.mb-16.mx-2.remove-btn {
         margin-top: 40px !important;
         margin-bottom: 40px !important;
     }
     .newjob-next {
         flex-direction: row;
         align-items: center;
     }
     .newjob-next .left {
         flex-direction: column;
     }
     .newjob-next .right {
         margin-bottom: 0;
     }
     .newjob-next .left .mobile-mt-0 {
         margin-top: 0;
         flex-direction: column;
     }
     .owner-operators-value input {
         max-height: 30px;
         height: 30px;
         padding: 10px;
     }
     .addbtn {
         margin: 19px 0;
     }
     .Notify-d {
         margin-bottom: 40px;
     }
     .margin-x-0 {
         margin-left: 0 !important;
         margin-right: 0 !important;
     }
     .FusePageSimple-wrapper .profie-btns button {
         display: flex;
         flex-direction: row;
         justify-content: center;
         align-items: center;
         font-size: 16px !important;
         color: #000;
         background: #e9c917;
         border-radius: 5px;
         height: 50px;
         border-bottom: 0 !important;
         max-height: 50px;
         min-height: 50px;
         margin: 0 10px;
     }
     .profie-btns button span.material-icons {
         color: #000 !important;
         margin-bottom: 0;
         margin-right: 5px;
     }
     .profie-btns button[aria-selected=true] {
         background: #000;
         color: #e9c917 !important;
     }
     .profie-btns button[aria-selected=true] span.material-icons{
         color: #e9c917 !important;
     }
     .profie-btns span.MuiTabs-indicator {
         display: none;
     }
     .profie-btns {
         margin-bottom: 0 !important;
     }
     .jobticket .jobticket-full {
         width: 100% !important;
     }
     .jobticket .jobticket-full .line1 {
         padding: 15px;
     }
     .jobticket .jobticket-full .line1 .text-72 {
         font-size: 20px;
         line-height: 1;
         padding: 0 5px;
     }
     .jobticket .jobticket-full .line1 .text-32 {
         font-size: 16px;
         line-height: 1;
     }
     .jobticket .jobticket-full button {
         flex-direction: row;
     }
     .items-end.jobticket-info {
         flex-direction: column;
         justify-content: start;
         align-items: start;
         padding: 20px 0 0px 0;
     }
     .items-end.jobticket-info .flex-col {
         padding: 15px 0;
         border-bottom: 1px solid #ccc;
         width: 100%;
     }
     .items-end.jobticket-info .flex-col:last-child {
         border-bottom: 0px;
     }
     .items-end.jobticket-info .flex-col button {
         margin-top: 10px;
     }
     .jobticket-full.jobticket-detail {
         padding: 15px;
     }
     .jobticket-btns .MuiTabs-flexContainer {
         flex-direction: column;
     }
     .jobticket-btns .MuiTabs-flexContainer button {
         margin: 0 !important;
         max-width: 100% !important;
         margin-bottom: 15px !important;
     }
     .tickettypes {
         padding: 0 !important;
     }
     .tickettypes .flex-col {
         margin-bottom: 15px;
     }
     .tickettypes .flex-col .MuiAccordion-gutters {
         margin: 10px 0;
         border: 1px solid #ccc;
     }
     #fuse-toolbar .bg-dark.p-3 {
         height: 40px;
         width: 40px;
         padding: 0 !important;
         display: flex;
         justify-content: center;
         align-items: center;
     }
     .jobticket-serch-nd-btns .max-w-512 {
         max-width: 41%;
     }
     .jobticket-serch-nd-btns button {
         flex-direction: row;
         font-size: 11px;
     }
     .jobticket-serch-nd-btns button svg {
         height: 15px;
         width: 15px;
     }
     .items-end.jobticket-info .flex-col p.MuiTypography-root {
         font-size: 14px;
     }
     .MuiCard-root.mttabs {
         padding: 0 !important;
     }
     #fuse-toolbar .MuiCard-root.mttabs button {
         font-size: 14px !important;
     }
     .tickettypes .flex-col p {
         font-size: 14px;
         margin: 5px 0;
     }
     .jobTprogress h6.MuiTypography-root {
         font-size: 14px;
         margin-bottom: 6px;
     }
     .jobticket-serch-nd-btns {
         flex-direction: column;
     }
     .jobticket-serch {
         align-items: start;
     }
     .jobticket-serch-nd-btns .max-w-512 {
         max-width: 100%;
     }
     .jobticket-serch {
         flex-direction: column;
     }
     .jobticket-serch-nd-btns .py-20 {
         padding: 5px;
     }
     .jobticket-serch-nd-btns {
         margin-bottom: 20px;
     }
     .checkbox-text span {
         font-size: 1.2rem!important;
         line-height: 18px;
     }
     .checkbox-text label {
         margin-right: 0;
         margin-bottom: 15px;
     }
     .moble-content h5{
         padding: 0 15px ;
         font-size: 18px;
         padding-top: 20px;
     }
     .jobstable .responsive-loads-table .MuiFormControl-root.MuiFormControl-fullWidth {
         padding: 0 15px;
         margin-bottom: 10px !important;
         margin-top: 15px;
     }
     .jobstable .responsive-loads-table .MuiFormControl-root.MuiFormControl-fullWidth label {
         left: 10px;
     }
     .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
         border-radius: 5px;
     }
     .job-number {
         display: flex;
         justify-content: flex-start;
         align-items: center;
         border-bottom: 1px solid #cccccc96;
         height: 45px;
         margin-bottom: 15px;
     }
     .jobticket .jobticket-full .line1 .csm p b.font-bold {
         font-weight: 400;
     }
     .jobticket .jobticket-full button.job-edit-btn {
         position: absolute;
         top: 0;
         right: 15px;
         width: 100%;
         max-width: 80px;
         top: 15px;
         padding: 5px 0;
         font-size: 12px;
     }
     .jobticket .jobticket-full button.job-edit-btn svg {
         height: 15px;
         width: 15px;
         margin-right: 5px;
     }
     .jobticket .jobticket-full .line1 .csm p {
         margin: 2px 0;
         font-size: 14px;
     }
     .jobticket .jobticket-full .line1 {
         padding-bottom: 0;
     }
     .items-end.jobticket-info .flex-col p b.font-bold {
         font-weight: 400;
         margin-right: 5px;
     }
     .items-end.jobticket-info .flex-col p:first-child b {
         font-weight: 600;
     }
     .job-notes h2 {
         padding: 15px;
         line-height: 1;
         background: #f4f4f4;
         margin-bottom: 15px;
     }
     .job-notes button {
         width: 100%;
         max-width: 70px;
         border-radius: 5px;
         margin: 0 auto;
     }
     /* button.MuiButton-root, a.MuiButton-root{
         border-radius: 5px;
     } */
     .items-end.jobticket-info .flex-col button img {
         height: 15px;
         margin-right: 5px !important;
     }
     .jobTprogress .muiltr-1nvtat8-MuiLinearProgress-root {
         height: 30px !important;
         border-radius: 5px !important;
     }
     #fuse-main .jobticket-btns .MuiTabs-flexContainer button {
         margin: 0 !important;
         max-width: 100% !important;
         margin-bottom: 10px !important;
         padding: 0 !important;
         height: 30px !important;
         font-size: 14px !important;
         font-weight: 400;
         border: 0;
         border-radius: 5px;
     }
     .job-dropdwon {
         min-height: 40px;
     }
     .jobticket-serch {
         align-items: start !important;
     }
     .jobticket-serch .flex-1 span {
         display: block;
         margin: 0 5px;
     }
     .jobticket-serch .flex-1 {
         margin-bottom: 10px;
     }
     .jobticket-serch-nd-btns .flex-1 {
         margin-bottom: 0;
     }
     .jobticket-serch-nd-btns {
         margin-top: 10px;
     }
     .jobticket-serch-nd-btns .max-w-512 {
         margin-bottom: 10px;
     }
     .tickets-btns {
         display: flex;
         width: 100%;
         justify-content: flex-end;
     }
     .tickets-btns button svg {
         margin-right: 5px;
     }
     .batch-dialog button {
         background: #EDCA33;
         color: #000;
     }
     .batch-dialog .MuiPaper-rounded {
         box-shadow: none;
     }
     .stdate-fdate {
         flex-direction: column;
     }
     .muiltr-jm7wdg .FusePageCarded-header {
         min-height: 120px;
         height: 145px;
         align-items: center;
     }
     .job-edit-form {
         flex-direction: column;
         align-items: start !important;
     }
     .flex.job-edit-form-btns {
         margin-top: 10px;
         width: 100%;
         align-items: center;
         justify-content: end;
     }
     .mb-10.disable-job {
         display: flex;
         flex-direction: row-reverse;
         align-items: center;
         justify-content: flex-start;
     }
     .mt-8.mb-16.customers {
         margin-left: 0 !important;
         padding-left: 0 !important;
     }
     .form-new-customer {
         flex-direction: column;
     }
     .add-new-customer .form-new-customer input {
         height: 30px;
         padding: 10px 15px !important;
     }
     .MuiDialogContent-root.add-new-customer-p {
         padding: 0 15px;
     }
     .form-new-customer .MuiFormControl-fullWidth {
         margin-bottom: 5px;
     }
     /* .add-new-customer .form-new-customer .state input {
         padding: 0 !important;
     } */
     .add-new-customer .form-new-customer .MuiInputBase-formControl {
         padding: 0;
     }
     .job-notes .addbtn button, .job-notes .remove-btn button {
         width: 40px;
         border-radius: 100%;
     }
     .job-notes br {
         display: none;
     }
     .job-notes .job-notes-heading {
         font-size: 16px;
         line-height: 1;
         margin-bottom: 0;
     }
     .MuiAutocomplete-fullWidth.state {
         margin-bottom: 0;
     }
     .MuiDialogContent-root.add-new-customer-p {
         margin-top: 15px;
     }
     .job-notes .job-notes-heading.contact-h {
         margin-top: 15px;
     }
     .MuiDialog-paperWidthLg.MuiDialog-paperFullWidth {
         margin: 15px;
         width: calc(100% - 30px);
     }
     .customer_website input {
         height: 30px;
         padding: 10px !important;
     }
     .customer_website {
         margin-bottom: 0  !important;
     }
     .term-credit-limt {
         flex-direction: column;
         margin-top: 0;
         margin-bottom: 0;
     }
     .term-credit-limt input {
         height: 30px;
         padding: 10px !important;
     }
     .margin-b-0 .MuiSelect-root {
         margin-bottom: 5px;
     }
     .margin-b-0 .MuiFormControl-fullWidth {
         margin-bottom: 0;
     }
     .customer_website-mb-0 {
         margin-bottom: 5px;
     }
     .customer-addbtn.addbtn {
         margin-top: 35px;
     }
     .term-credit-limt .term {
         top: 10px;
     }
     .newjob-next .right {
         width: 100%;
         justify-content: end;
     }
     .newjob-next {
         align-items: start !important;
     }
     .jpb-updates .MuiTypography-h6 {
         font-size: 16px;
         line-height: 1;
     }
     .owner-operator {
         margin-top: 0;
     }
     .job-notes.batch-dialog .MuiPaper-rounded {
         max-height: calc(100% - 30px);
         margin: 15px;
     }
     .job-notes.batch-dialog .MuiPaper-rounded input {
         padding: 10px;
         height: 30px;
     }
     .MuiDialogContent-root.batch-dialog-form {
         padding: 15px;
     }
     .stdate-fdate .MuiFormControl-fullWidth {
         margin: 0;
         margin-bottom: 5px;
     }
     .PrivatePickersToolbar-root span.MuiTypography-root {
         font-size: 16px;
         line-height: 1;
     }
     /* .MuiTabs-flexContainer .MuiButtonBase-root.MuiTab-root {
         height: 40px !important;
         border: 1px solid #000;
         border-radius: 5px;
     } */
     .driver-list-btns button {
         width: 45%;
         padding: 10px;
         margin: 10px;
         background: #000;
     }
     .driver-list-btns button[aria-selected=false] {
         background: #e9c917;
         color: #000;
     }
     .driver-list-btns .MuiTabs-indicator {
         display: none;
     }
     .ChatApp-contentCard {
         border-radius: 5px !important;
     }
     .MuiDrawer-paper {
         width: 100% !important;
     }
     .jobstable .responsive-loads-table table tr th b {
         max-width: 60%;
         word-wrap: break-word;
         display: inline-block;
     }
     .jobstable .responsive-loads-table {
         padding: 0 0px;
     }
     .job-section .mob-mt .alljobs-headr {
         flex-direction: row !important;
         padding-left: 20px;
     }
     .jobstable .responsive-loads-table .responsive-loads-table table {
         width: 100%;
     }
     .customer-full-table .responsive-loads-table .MuiAccordionSummary-root {
         background: #f4f4f4;
         padding: 0;
     }
     .customer-full-table .responsive-loads-table > div > table {
         display: none;
     }
     .customer-full-table .responsive-loads-table .p-16.w-full {
         padding: 0;
     }
     .customer-full-table .responsive-loads-table .MuiAccordion-gutters {
         padding: 10px;
     }
     .customer-full-table .responsive-loads-table .mytablestyle .p-5 {
         padding: 0;
         margin-top: 10px;
     }
     .customer-full-table .responsive-loads-table .Mui-expanded {
         margin: 0;
     }
     .muiltr-buvykw-MuiPaper-root-MuiDialog-paper {
         max-height: calc(100% - 64px) !important;
         width: calc(100% - 30px) !important;
         margin: 15px !important;
     }
     .edit-customers .flex {
         flex-direction: column;
     }
     .updatecustomer {
         flex-direction: row !important;
     }
     .createcustomer input {
         height: 30px;
         padding: 10px !important;
     }
     .createcustomer {
         padding-bottom: 20px;
     }
     .job-notes.e-customer h2 {
         margin-bottom: 0;
     }
     .edit-customers {
         padding: 0;
     }
     .edit-customers .FusePageCarded-header {
         min-height: 90px;
         height: auto;
     }
     .s-state input {
         padding: 0 !important;
     }
     .customer-full-table {
         flex-direction: column !important;
     }
     .customer-full-table header .MuiToolbar-gutters {
         padding-left: 50px;
     }
     .customer-full-table header .MuiToolbar-gutters .px-16 {
         padding: 0 5px;
     }
     .customer-70-table {
         overflow: auto;
     }
     .customer-full-table .clientpage header .MuiToolbar-gutters {
         padding-left: 15px;
     }
     .muiltr-3cyr95-MuiPaper-root-MuiAccordion-root:last-of-type .MuiAccordionSummary-gutters {
         border-bottom: 1px solid #e1dfdf;
     }
     .muiltr-1q9uopw-MuiButtonBase-root-MuiButton-root {
         border-radius: 5px;
     }
     .new-customers div input {
         padding: 16.5px 14px !important;
     }
     .ModalGenerateInvoicesPrint .bgdiv {
         padding: 0;
         flex-basis: 10px;
         flex-grow: 0;
         max-width: 10px;
     }
     .ModalGenerateInvoicesPrint .verticalText {
         max-width: 15px !important;
         padding: 0;
     }
     .ModalGenerateInvoicesPrint table td {
         padding: 5px 0px;
         font-size: 12px;
     }
     .ModalGenerateInvoicesPrint table th {
         padding: 5px 0px;
         font-size: 12px;
     }
     .ModalGenerateInvoicesPrint table tr:last-child td {
         padding: 5px 10px;
     }
  
     .salarytoolbar .FusePageCarded-toolbar {
         height: auto;
         min-height: auto;
         padding: 10px;
     }
     .salarytoolbar .FusePageCarded-toolbar .tabbtns .MuiTabs-flexContainer button {
         margin-right: 5px;
         padding: 10px !important;
         height: 40px !important;
         line-height: 1;
         min-height: 40px !important;
     }
     .salarytoolbar .FusePageCarded-toolbar .tabbtns .MuiTabs-indicator {
         display: none;
     }
     .salarytoolbar .FusePageCarded-toolbar .tabbtns .h-64 {
         height: 100%;
     }
     .salarytoolbar .FusePageCarded-toolbar .tabbtns.select-btn .MuiSelect-select {
         padding: 10px;
         font-size: 12px;
         padding-right: 30px;
     }
     .salarytoolbar .FusePageCarded-toolbar .tabbtns .MuiTabs-flexContainer button.Mui-selected {
         color: #edca33;
         background: #000;
     }
     .earningsreports .MuiTabs-scroller {
         align-items: center;
         display: flex;
     }
     .new-uninvoiced-details {
         flex-direction: column;
         justify-content: center !important;
         align-items: start !important;
         padding: 15px;
     }
     .new-uninvoiced-details .main-column:first-child {
         margin-bottom: 10px;
     }
     main.customer-70-table {
         width: 100%;
     }
     .cst__table.new-invoice-table td.top-data:nth-child(2) p {
         font-size: 20px;
     }
     .new-invoice-table .top-data p {
         font-size: 14px;
     }
     .new-invoice-table .top-data p {
         text-align: right;
     }
     .cst__table.new-invoice-table {
         margin-bottom: 15px;
     }
 /*     
 }
 @media screen and (max-width : 767px) { */
     .onprint {
         width: 90%;
     }
     /* .onprint .printsizesignature img {
         height: 50px !important;
     } */
     .pdfprintstyle > div {
         padding: 15px 10px 0px !important;
     }
     .pdfprintstyle .first-row {
         padding: 0 10px !important;
     }
     .onprint > div:first-child {
         padding: 3px 10px !important;
         border-radius: 5px !important;
     }
     .printsizefont.printsizedt p.py-8 {
         padding: 0px 0px 4px;
         font-size: 12px;
     }
     .printsizefont.printsizedt .mb-10 {
         margin-bottom: 0;
     }
     .printsizefont.printsizedt {
         padding: 5px;
     }
     .onprint {
         border-radius: 5px;
     }
     .onprint > div:first-child img {
         height: 14px;
         margin: 4px !important;
     }
     .userframe {
         margin-bottom: 10px;
     }
     .second-row.printsizeheading svg {
         height: 13px;
         width: 13px;
     }
     .imgbox.printsizelogo img {
         height: auto !important;
         width: auto;
     }
     .imgbox.printsizelogo {
         display: flex;
         justify-content: center;
         align-items: center;
     }    
     .w-full.ModalGenerateInvoicesPrint {
         overflow: auto;
     }
     .w-full.ModalGenerateInvoicesPrint > div {
         width: 1000px;
     }
     .mainpdfview .MuiTypography-h6 {
         font-size: 16px;
         line-height: 20px;
     }
     .mainpdfview .MuiTypography-body1 {
         font-size: 12px;
     }
     .ModalGenerateInvoicesPrint .verticalText {
         max-width: 25px !important;
         margin: auto 10px !important;
     }
     .ModalGenerateInvoicesPrint .cstPdf {
         flex-basis: 94.333333%;
         flex-grow: 0;
         max-width: 94.333333%;
     }
     .MuiPaper-root.MuiPaper-elevation .edit-customers div input {
         height: 20px !important;
         padding: 10px !important;
     }
     .edit-customers .flex {
         margin: 0;
     }
     .new-customers-address .mx-4,
     .edit-customers .mx-2 {
         margin-left: 0;
         margin-right: 0;
     }
     .new-customers {
         margin-top: 10px !important;
     }
     .MuiPaper-root.MuiPaper-elevation .edit-customers div .state input {
         padding: 0 !important;
         height: 22px !important;
     }
     .edit-customers .flex .mb-16 {
         margin-bottom: 5px;
     }
     .muiltr-9ntcwb .FusePageCarded-header {
         height: auto !important;
         min-height: auto !important;
     }
     .fuellog-sm > div {
         width: 100%;
         text-align: center;
         padding: 5px;
     }
     .flex.fuellog-sm {
         flex-direction: column !important;
     }
     .muiltr-9ntcwb .FusePageCarded-header {
         padding-top: 15px;
     }
     .fuellogdetail-tab .MuiTabs-flexContainer button.MuiTab-root {
         background: transparent !important;
         color: #000 !important;
         border: none !important;
     }
     /* .MuiTabs-flexContainer .MuiButtonBase-root.MuiTab-root {
         height: 62px !important;
     } */
     .fuel-log .flex.-mx-6 {
         margin-left: 0 !important;
         flex-direction: column;
         margin-right: 0 !important;
     }
     .fuel-log .flex.-mx-6 .mx-4 {
         margin-left: 0;
         margin-right: 0;
     }
     .fuel-log input#price {
         padding: 10px;
     }
     .fuel-log .flex.-mx-6 .mb-16 {
         margin-bottom: 5px;
     }
     .reportmileage-col {
         margin: 0 !important;
         flex-direction: column;
     }
     .reportmileage-col .mx-4 {
         margin-left: 0;
         margin-right: 0;
         margin-bottom: 5px;
     }
     .reportmileage-log .idx-trucks-p .MuiSelect-select {
         padding: 10px;
     }
     .reportmileage-log .dr-user-id .MuiSelect-select {
         padding: 10px;
     }
     .reportmileage-footer {
         margin-top: 0;
     }
     .fuellog-btns {
         display: grid;
         grid-template-columns: 48% 48%;
         padding: 0 !important;
         padding-bottom: 10px !important;
         grid-gap: 10px;
     }
     .fuellog-btns button.MuiButton-root, .fuellog-btns a.MuiButton-root {
         margin: 0;
         width: 100%;
     }
     .fuellog-sm .fuellog-icon span {
         display: block;
     }
     .fuel-loglist > div {
         padding: 5px 0;
     }
     .reportmileage-log form h2 {
         display: flex;
         align-items: center;
         font-size: 18px;
         font-weight: 500;
     }
     .reportmileage-log form .MuiDialogContent-root {
         padding: 15px;
     }
     .reportmileage-log form br {display: none;}
     .MuiDialog-paper {
         margin: 15px !important;
     }
     .flex.justify-between.w-full.earningsreports {
         flex-direction: column;
     }
     .salarytable {
         padding: 15px;
     }
     .salarytoolbar .FusePageCarded-toolbar .tabbtns.select-btn .MuiSelect-select {
         padding: 10px;
         font-size: 10px;
         padding-right: 43px;
     }
     .earningsreports .MuiTabs-scroller {
         display: block;
         width: 100%;
     }
     .salarytoolbar .FusePageCarded-toolbar .tabbtns.select-btn .MuiOutlinedInput-root {
         width: 100%;
     }
     .earningsreports .MuiTabs-scroller .MuiTabs-flexContainer {
         display: grid;
         grid-template-columns: 50% 50%;
         grid-gap: 4px;
     }
     .earning-dialog .avtarimg {
         width: 100% !important;
     }
     .earning-dialog .earning-select {
         padding: 15px;
     }
     .earning-dialog .earning-select .w-full {
         padding: 0;
         margin: 0;
     }
     .earning-dialog .earning-select .w-full .MuiSelect-select {
         font-size: 10px;
         padding: 10px;
     }
     .earning-dialog .earning-select .w-full .MuiInputBase-root {
         width: 100%;
     }
     .earning-dialog .earning-select .MuiBox-root {
         padding: 20px 0;
         text-align: center;
     }
     .muiltr-1tz4nvp-MuiPaper-root-MuiDialog-paper {
         max-width: 100% !important;
     }
     .muiltr-siivb9-MuiPaper-root-MuiDialog-paper.MuiDialog-paperScrollBody {
         max-width: 100%;
     }
     .timesheet {
         justify-content: center;
     }
     .timesheet .select-btn .MuiSelect-select {
         font-size: 10px;
         padding: 10px;
         padding-right: 30px;
     }
     .timesheet .select-btn {
         width: 100%;
         padding: 0 15px;
     }
     .timesheet .select-btn .MuiOutlinedInput-root {
         width: 100%;
     }
     .timesheetlist {
         padding: 15px;
     }
     .timesheetprofile {
         width: 100% !important;
     }
     header.MuiPaper-root.timesheetheader {
         width: 100%;
     }
     .timesheetdialogtable {
         padding: 10px 15px;
     }
     .timesheetdialogtable .MuiPaper-rounded {
         width: 100%;
     }
     .timesheetdialogtable header {
         padding: 10px;
     }
     .timesheetdialogtable header h5 {
         font-size: 14px;
         margin: 0;
     }
     .timesheetdialogtable .MuiCardContent-root {
         padding: 0;
     }
     .timesheetdialogtable .MuiCardContent-root tr th {
         padding: 5px;
     }
     .timesheetdialog .timesheet {
         margin-top: 10px;
     }
     .timesheetdialogtable .MuiCardContent-root table table tr th {
         padding: 6px 16px;
     }
     .userlistpage button {
         padding: 12px;
     }
     .userlisttabs {
         width: 100%;
         padding-top: 24px;
     }
     .userlisttabs .MuiTabs-flexContainer {
         width: 100%;
         display: grid;
         padding: 0;
         grid-template-columns: 46% 46%;
         grid-gap: 10px;
         justify-content: center;
     }
     .FusePageCarded-contentCard .userlisttabs-inner .tabbtns button {
         margin-right: 5px;
         padding: 10px !important;
         height: 40px !important;
         line-height: 1;
         min-height: 40px !important;
     }
     .userlisttabs .userlisttabs-inner .MuiTabs-scrollableX {
         height: 42px;
     }
     .userlisttabs .userlisttabs-inner span.MuiTabs-indicator {
         display: none;
     }
     .FusePageCarded-contentCard .userlisttabs-inner .tabbtns button.Mui-selected {
         color: #edca33;
         background: #000;
     }
     .companies-dialog-w-full {
         width: 100% !important;
     }
     .companies-btns {
         display: grid;
         width: 100%;
         justify-content: center;
         align-items: center;
         grid-template-columns: 100px 100px 100px;
         grid-gap: 10px;
         padding-bottom: 15px;
         background: rgb(0, 0, 0);
     }
     .companies-btns .btn {
         width: 100%;
         padding: 0;
     }
     .companies-btns button {
         width: 100%;
     }
     .companies-dialog-inner1 .MuiTabs-flexContainer {
         justify-content: center;
     }
     .companies-dialog-inner1 {
         padding: 0;
         padding-top: 15px;
     }
     .salaryheader {
         padding: 0;
     }
     .salaryheader .flex {
         padding: 0;
     }
     .timeline-dialog-profile {
         width: 100% !important;
     }
     main.ChatApp-contentWrapper {
         width: 100%;
     } 
     .ChatApp-content {
         overflow: auto;
     }
     .cst__table.new-invoice-table .cst-table-data:nth-child(8), .cst__table.new-invoice-table .cst-table-data:nth-child(9), .cst__table.new-invoice-table .cst-table-data:nth-child(10) {
         width: auto;
     }
     .cst__table.new-invoice-table .cst-table-data {
         display: block;
     }
     .cst__table.new-invoice-table.customnew-heading .MuiAccordion-root table td:last-child {
         width: auto;
     }
     .cst__table.new-invoice-table.customnew-heading > table th.top-head {
         width: 120px;
         line-height: 20px;
     }
     .cst__table.new-invoice-table.customnew-heading > table th.top-head:first-child {
         width: auto;
     }
     .cst__table.new-invoice-table.customnew-heading .MuiAccordion-root table td.top-data {
         width: 108px;
     }
     .cst__table.new-invoice-table.customnew-heading .MuiAccordion-root table td.top-data:first-child {
         width: 65px;
     }
     .new-uninvoiced-details .main-column {
         width: 100%;
     }
     .new-uninvoiced-details .main-column .user-edit-detail {
         float: right;
     }
     .cst__table.new-invoice-table.customnew-heading > table {
         table-layout: auto;
     }
     .cst__table.new-invoice-table.customnew-heading > table th.top-head:first-child {
         width: 50px;
     }
     .cst__table.new-invoice-table.customnew-heading > table th.top-head {
         width: 105px;
         padding: 10px;
     }
     .cst__table.new-invoice-table.customnew-heading .MuiAccordion-root table td.top-data {
         width: 105px;
         padding: 10px;
         text-align: center;
     }
     .new-invoice-table .top-data p {
         text-align: center;
     }
     .cst__table.new-invoice-table.customnew-heading > table th.w-1\/4 {
         width: auto;
     }
     .cst__table.new-invoice-table.customnew-heading .MuiAccordion-root table td.top-data:first-child {
         width: 35px;
     }
     .MuiGrid-root.jobbixes1 {
         margin-top: 25px !important;
         flex-direction: column;
     }
     .MuiGrid-root.jobbixes1 .jobbixes4 .job-number {
         flex-direction: column;
         align-items: start;
         border: 0;
     }
     .jobgraph {
         margin-left: 0;
     }
     .MuiGrid-root.jobbixes1 .jobbixes4 {
         border-right: 0 !important;
     }
     .MuiGrid-root.jobbixes1 .jobbixes6 {
         border-right: 0 !important;
     }
     .jobticket-btns .MuiTabs-scroller .MuiTabs-flexContainer {
         display: inline-block !important;
         width: 100%;
     }
     #fuse-main .jobticket-btns .MuiTabs-flexContainer button {
         margin: 0 5px !important;
         padding: 10px !important;
     }
     .jobticket-btns .MuiTabs-scroller .MuiTabs-flexContainer .jobticket-serch-nd-btns .MuiPaper-root {
         margin: 0;
         margin-bottom: 15px;
     }
     .MuiGrid-root.jobbixes3 {
         flex-direction: column;
     }
     .ecommerce-header.inspection-outter .FusePageCarded-header {
        max-height: unset;
    }
    .ecommerce-header.inspection-outter .newjobdesign .MuiGrid-item.grid-full {
         padding-top: 26px;
    }
    .ecommerce-header.inspection-outter .addtruck-sec svg {
        height: 70px;
        width: 80px;
    }
    .mobilefileds-form .addtruck-sec .muiltr-q8hpuo-MuiFormControl-root.mobile-width-half {
        padding: 10px !important;
    }
    .customfuse-card .truck-buttons.desktop-none {
        display: block;
        position: absolute;
        top: 0;
        padding: 0;
        right: 0;
    }
    .customfuse-card .mobile-truck-list {
        padding: 0 !important;
        margin-right: 0;
        margin-left: 0;
    }
}

@media only screen and (max-width:766px){
    .responsive-loads-table .MuiChip-colorDefault.MuiChip-clickable.MuiChip-clickableColorDefault.MuiChip-filledDefault span.material-icons.notranslate.MuiIcon-root.MuiIcon-fontSizeMedium.MuiChip-icon.MuiChip-iconMedium.MuiChip-iconColorDefault {
        direction: rtl;
        width: 100%;
        text-align: right;
    }
    .responsive-loads-table .MuiChip-colorDefault.MuiChip-clickable.MuiChip-clickableColorDefault.MuiChip-filledDefault {
        justify-content: unset !important;
    }
}

@media only screen and (max-width : 599px){
     .new-truck-main {
         margin-top: 90px;
     }
     .new-truck-sidebarcontent {
         flex-direction: column;
         justify-content: center;
     }
     .new-truck-main .flex.items-center {
         width: 100%;
         margin-bottom: 20px;
     }
     .muiltr-tifi6e .FusePageCarded-header {
         min-height: auto;
         height: auto;
     }
     .new-truck-main .flex.items-center.flex-1 {
         margin: 0;
     }
     .custom-heading {
         font-size: 20px;
     }
     .main-uninvoiced-details .custom-uninvoiced h6 {
         font-size: 20px !important;
         margin: 10px 0;
     }
     .new-uninvoiced-details .flex {
         flex-direction: column;
     }
     .new-uninvoiced-details .flex.gap-40 {
         grid-gap: 20px;
         margin-top: 10px;
         flex-direction: row;
     }
     .text-32 {
         font-size: 18px;
     }
     .muiltr-tifi6e .FusePageCarded-header {
         height: 240px;
         min-height: 240px;
         max-height: 240px;
     }
     .new-truck-main {
         margin-top: 0;
     }
     .ecommerce-header.inspection-outter .addtruck-sec svg {
        height: 50px;
        width: 50px;
    }
}

@media only screen and (max-width:500px){
    .pdfprintstyle.ticket-no {
        height: 610px;
    }
    .drivermodal .MuiDrawer-paper.MuiDrawer-paperAnchorRight {
        width: 80% !important;
    }
    .ecommerce-header.inspection-outter .FusePageCarded-contentWrapper {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .new-modal.edit-company-info .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
        width: 98% !important;
    }
    .customer-detail-main button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedThird.MuiButton-sizeMedium {
        padding: 5px 10px;
    }
}
 
@media only screen and (max-width:479px){
    .trasaction-outter td.MuiTableCell-root {
        display: flex;
        flex-wrap: wrap;
        text-align: right !important;
        justify-content: flex-end;
        padding-bottom: 8px;
        padding-top: 10px;
        padding-left: 106px !important;
    }
    .trasaction-outter td.MuiTableCell-root:before {
        left: 18px !important;
        position: absolute;
    }
    
    .popup-close svg {
        width: 18px !important;
    }
}

@media only screen and (max-width:360px){
    #fuse-main .jobticket-btns .MuiTabs-flexContainer button {
        font-size: 13px !important;
    }
    #fuse-main .jobticket-btns .MuiTabs-flexContainer button {
        margin: 0 2px !important;
    }
}
 
 .step-form-bar select {
     border: 1px solid #E2E1E5 !important;
     border-radius: 5px;
     padding: 14px 15px;
     height: 48px;
     max-width: 200px
 }
 
 select {
     -webkit-appearance: unset;
 }
 .configuration-panel .MuiPaper-rounded {
     border-radius: 0;
     box-shadow: none;
 }
 .configuration-panel thead {
     background-color: #000;
 }
 .configuration-panel thead th {
     font-size: 16px;
     color: #fff;
     font-weight: 500;
 }
 .configuration-panel tbody tr:nth-child(even) {
     background: #fdf8f8;
 }
 .configuration-panel tbody button {
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 7px 20px;
     background-color: #e9c917;
     border-radius: 30px;
     margin: 0 !important;
     width: 100%;
     max-width: 65px;
     float: left;
 }
 .configuration-panel tbody button img {
     max-width: 10px;
     margin-right: 5px;
 }
 .ttile-btn button {
     padding: 7px 20px;
     background-color: #e9c917;
     border-radius: 30px;
     font-weight: 600;
 }
 .configuration-dialog .MuiDialog-paperWidthSm {
     padding: 40px;
 }
 .configuration-dialog form select {
     appearance: auto;
     padding: 10px;
     border: 1px solid #ccc;
     margin-bottom: 0;
 }
 .configuration-dialog form input {
     padding: 10px;
     border: 1px solid #ccc;
     margin-bottom: 0;
     width: 100%;
 }
 .configuration-dialog form .close-btn {
     background: #eccf44;
     color: #000;
     font-weight: 600;
     position: absolute;
     top: 0;
     right: 0;
     width: 40px;
     height: 40px;
     border-radius: 0 0 0 14px;
 }
 .configuration-dialog form .close-btn img {
     margin: 0 auto;
     width: 23px;
 }
 .configuration-dialog form .sbmt-btn {
     padding: 10px 20px;
     background-color: #000;
     border-radius: 5px;
     color: #fff;
     text-transform: capitalize;
     transition: all 0.5s;
 }
 .configuration-dialog form .sbmt-btn:hover {
     background-color: #eccf44;
 }
 .configuration-dialog form select:focus, .configuration-dialog form input:focus, .configuration-dialog form textarea:focus {
     outline-color: #ccc;
 }
 .configuration-dialog form p.errmsg {
     color: red;
     font-size: 11px;
     line-height: 15px;
 }
 .configuration-dialog form .form-field {
     margin-bottom: 10px;
 }
 .configuration-dialog .MuiDialog-paperWidthSm {
     max-width: 800px;
     width: 100%;
 }
 .configuration-dialog form textarea {
     padding: 10px;
     border: 1px solid #ccc;
     margin-bottom: 0;
     width: 100%;
 }
 .settingsModel.rightsidemenu .MuiDrawer-paperAnchorRight {
     width: 100%;
     max-width: 365px;
 }
 .rightsidemenu .title {
     background: #000;
 }
 .rightsidemenu .title p {
     color: #fff;
     font-weight: 500;
 }
 .settingsModel.rightsidemenu a.MuiButton-text {
     border-radius: 0;
     padding: 5px 15px;
     transition: all 0.5s;
     border-bottom: 1px solid #E3E3E3;
 }
 .settingsModel.rightsidemenu a.MuiButton-text:hover {
     background-color: #F7F7F7;
 }
 .settingsModel.rightsidemenu .s-timeclock button,
 .settingsModel.rightsidemenu .s-inspection button {
     max-width: fit-content;
 }
 .settingsModel.rightsidemenu .s-inspection {
     border-bottom: 1px solid #E3E3E3;
     padding-bottom: 10px;
 }
 .receipt-dialog .MuiDialog-paperWidthSm {
     padding: 20px 70px;
     max-width: 855px;
 }
 .form-field.v-tooltip {
     position: relative;
 }
 .form-field.v-tooltip svg {
     position: absolute;
     right: 8px;
     top: 8px;
 }
 .configuration-panel .MuiAlert-filledSuccess {
     position: absolute;
     right: 30px;
     top: 18px;
     width: 100%;
     max-width: 320px;
 }
 .configuration-panel button {
     background-image: none;
 }

 /* .form-field.v-tooltip svg {
     position: absolute;
     right: 8px;
     top: 26px;
 } */
 
 /* ///////////////////////////////////added css for add ticket///////////////////// */
 .close-x-btn {
     max-width: 32px;
     background: #ebce43;
     padding: 10px;
     display: flex;
     justify-content: center;
     margin: 0 0 0 auto;
     border-radius: 10px;
     margin-top: 8px;
 }
 
 button.add-ticket-btn {
     padding:15px;
     height: auto;
     border-radius: 10px !important;
     width: auto;
     margin: 0 !important;
     font-size: 13px;
     font-weight: 400 !important;
     background: #e9c917 !important;
 }
 .fuel-loglist.fuellog-sm .fuellog-form .max-w-512 {
     width: 333px;
     padding: 4px 10px;
     border-radius: 4px;
     border: 1px solid #ccc;
 }
 .fuel-loglist .MuiSelect-select {
     padding: 10px;
     background: #fff;
 }
 .fuel-loglist .MuiInputLabel-animated {
     top: -6px;
 }
 .fuel-loglist.fuellog-sm .fuellog-form {
     justify-content: end;
 }
table td:empty {
    padding: 0;
}







.view-btn {
    text-decoration: unset !important;
    border: 1px solid #EDCA33;
    padding: 4px 10px;
    display: inline-block;
    border-radius: 20px;
}

.leftside {
    width: 72%;
}
.rightside {
    width: 25%;
}
table.applypay tr td {
    font-size: inherit;
}

@media only screen and (max-width:1340px){
    .flex.dashboaedenroolment {
        padding-bottom: 0;
        padding-top: 10px;
    }
    .enrol-outter br {
        display: none;
    }
    .enrol-outter p:nth-child(1) {
        margin: 0  0 8px;
    }
    .px-12.enrol-outter {
        padding: 0 8px;
    }
    .enrol-outter p span.material-icons {
        margin-right: 8px;
        width: 23px;
        margin-top: 6px;
    }
}
@media only screen and (max-width:1199px) and (min-width:1025px){
    .leftside {
        width: 70%;
    }
    .rightside {
        width: 27%;
    }
}



@media only screen and (max-width:1024px) and (min-width:991px){
    .leftside {
        width: 70%;
    }
    .rightside {
        width: 27%;
    }
}

@media(max-width:1199px){
    .MuiModal-root:not(.MuiModal-hidden) .mobile-sidebar .MuiIconButton-sizeSmall {
        background-image: unset !important;
    }
    
    .MuiModal-root:not(.MuiModal-hidden) .mobile-sidebar .MuiIconButton-sizeSmall img {
        display: block;
    }
}


@media only screen and (max-width:990px){
    
    .rightside {
        width: 100%;
        margin: 30px 0 0;
    }
    .leftside{
        width:100%;
    }
}

@media only screen and (max-width:767px){
    .configuration-dialog form input {
        padding-right: 32px;
     }
    .innerfeilds, .countrycode {
        width: 100% !important;
    }
    .main-jobgrid .job-editbtn button.MuiButton-root.MuiButton-text {
        padding: 3px 22px;
        font-size: 13px !important;
    }
    .newlayoutjotticket .jobticket-serch-nd-btns button.MuiButton-root {
        padding: 13px 12px;
        width: 134px;
        height: unset;
    }
    .newlayoutjotticket .jobticket-btns .MuiTabs-flexContainer .MuiButtonBase-root.MuiTab-root {
        line-height: 19px !important;
        min-height: 38px !important;
        font-size: 13px !important;
    }
}

@media only screen and (max-width:640px){
    .configuration-dialog form input {
        padding-right: 35px;
     }
    .ticket-email-poup .MuiBox-root {
        width: 95% !important;
    }
    .payment-text {
        margin: 0 0 5px;
        width:100% !important;
    }
     .payment-cards{
        width:100% !important;
    }
    .payments{
        flex-wrap: wrap;
    }
    button.tabbing-text {
        padding-left: 10px;
        padding-right: 10px;
    }
    .user-img-upload .MuiBox-root {
        width: 240px;
        height: 240px;
        border-radius: 40px;
    }
}
@media only screen and (max-width:579px) {
    .configuration-dialog form input {
        padding-right: 40px;
     }
}
.clase-tabla {
    margin: 0;
  }
  
  @media screen and (min-width: 600px) {
    .clase-tabla {
      margin-left: 10px;   
    }
    
  }
  .clase-tabla-tabla {
    margin: 0;
    padding-top: 35px;
    
  }
  
  @media screen and (min-width: 600px) {
    .clase-tabla-tabla {
      margin-left: 70px;  
      padding-top: 0px; 
    }
  }
.new-modal select {
    -webkit-appearance: auto;
}
 
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
@media only screen and (max-width:960px) {
    .custom-border  {
        border-right: 0px !important;
    }
}

.user-profile-detail label input {
    padding: 3px;
}
.user-profile-detail {
    align-items: start;
}
@media only screen and (max-width:767px) {
    .job-right .job-srach {
        margin-right: 0px;
    }
    .job-add  {
        margin-top: 10px;
    }
}

@media only screen and (max-width:350px){
    .job-notes .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
        width: 300px !important;
     }
}



.job-search-by-date .MuiFormControlLabel-label {
    text-transform: capitalize;
}

.userbg {
    background-color: #BDBDBD;
    height: 35px;
    width: 35px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.setting-icons span.material-icons {
    margin-right: 10px;
}
span.setting-heading {
    display: block;
    padding: 20px 0 6px;
}
.subs-list li {
    list-style: none;
    position: relative;
    padding-left: 13px;
    text-align: left;
}
.subs-list li:after {
    height: 5px;
    width: 5px;
    background: #000;
    border-radius: 50px;
    position: absolute;
    content: '';
    left: 0;
    top: 7px;
}
.material-btns span.material-icons {
    color: #fff;
}
.ecommerce-header .FusePageCarded-header {
    height: unset;
    min-height: unset;
}
.inspection-outter .FusePageCarded-sidebarHeader.permanent {
    height: 140px;
    min-height: 140px;
}
.FusePageCarded-contentCard.inner-scroll.addnewjobpage {
    padding-top: 25px;
}
.category-feild input.MuiOutlinedInput-input.MuiInputBase-input {
    padding: 11px 18px !important;
}
.category-feild .MuiOutlinedInput-root.MuiInputBase-root {
    padding: 0;
}
.category-feild .muiltr-1dr8o4h-MuiFormLabel-root-MuiInputLabel-root {
    top: -4px;
}
.category-feild .muiltr-5na0qb-MuiFormLabel-root-MuiInputLabel-root {
    top: -7px;
}


.ecommerce-header.inspection-outter .MuiList-padding li.MuiListSubheader-root.MuiListSubheader-gutters {
    font-size: 16px;
    color: #000;
    font-weight: 700;
}

.customfuse-card span.MuiTypography-root {
    font-weight: 600;
    font-size: 14px;
}
.mileage-space .newjob, .mileage-space {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.addnewjobpage .jpb-updates .operator-tabel td {
    border-right: unset;
}
.profie-btns button svg{
    fill: #000 !important;
}

.job-notes .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
    width: 320px;
    margin: 15px auto !important;
}

.ticket-search{
    background: #fff;
     border: 1px solid black;
     height: 50px;
}
.ticket-table-icon button {
    border: unset;
    background: #efefef;
    border-radius: 30px;
}
.ticket-table-icon {
    padding: 12px 14px 0;
}
.inner-imgs a.loadz-btn:hover svg {
    fill: #000;
}
.inner-imgs a.loadz-btn:hover {
    background: #EDCA33;
}
.drivermodal .driver-list-edit .driverform-edit {
    padding: 40px;
}
.inner-imgs.edit-upload-img {
    padding: 12px 25px;
}
.adddriverbox td.MuiTableCell-root.MuiTableCell-sizeMedium {
    padding-left: 3px;
    padding-right: 3px;
}
.upload-img-inr {
    width: 100%;
}
.inner-imgs.logoinner-img {
    padding-bottom: 12px;
}
.adddriverbox .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-multiline.MuiInputBase-adornedStart {
    height: 100%;
    padding: 0 14px;
}
.MuiDialog-paperScrollPaper form .MuiDialogActions-root.MuiDialogActions-spacing {
    padding: 10px 28px  30px;
}
.driverouter-list .img-box.upload-img-inr {
    padding: 0;
    height: 200px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-img-text .userimg {
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 12%);
}

.userimg {
    max-height: 120px;
    max-width: 260px;
    overflow: hidden;
    margin: -90px auto 0;
}
.userimg img {
    max-height: 100%;
    max-width: 100%;
}

.user-img-text h2 {
    font-size: 24px;
    margin: 16px 0 0;
}
.user-img-text {
    padding-bottom: 5px !important;
}
.payment-outter h3.leading-none {
    color: rgba(237, 202, 51, var(--tw-bg-opacity));
    font-size: 28px;
    width: 100%;
    text-align: center;
}
.edit-account {
    text-decoration: underline;
    font-size: 13px !important;
    margin: 10px 0 0;
    text-align: center;
    margin-top: 5px !important;
}
.apply-payment-fee {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.apply-payment-fee p {
    font-size: 12px;
    padding: 0 2px;
}
.payment-outter  .inputfields.px-20 {
    padding: 0 !important;
}
.payment-outter  .payment-text {
    --tw-text-opacity: 1;
    color: rgba(141, 144, 151, var(--tw-text-opacity));
    font-size: 13px !important;
}

.create-customer h2 {
    font-size: 22px;
    padding: 20px 40px !important;
    border-bottom: 1px solid #E2E1E5;
    font-weight: 700 !important;
}
.create-customer .edit-customers  .FusePageCarded-contentWrapper {
    padding: 0 !important;
}
.create-customer .edit-customers {
    padding: 30px 40px;
}
.create-customer .edit-customers .FusePageCarded-header {
    min-height: unset;
    height: unset;
}
.create-customer .FusePageCarded-contentCard.inner-scroll.addnewjobpage {
    padding-top: 0;
}

/* .create-customer .new-customers input.MuiOutlinedInput-input, .addnewjobpage .MuiInputBase-formControl input.MuiOutlinedInput-input {
    padding: 5px !important;
} */
.create-customer .new-customers label, .create-customer label {
    top: -3px;
}
.create-customer .new-customers label.MuiInputLabel-shrink, .create-customer label.MuiInputLabel-shrink {
    top: -8px;
    color: #EDCA33;
    left: -10px;
}

.customer_popup_new fieldset {
    border: unset!important;
}
.customer_popup_new .muiltr-1rchl7y-MuiInputBase-root-MuiOutlinedInput-root, .customer_popup_new  .muiltr-cs5xvo-MuiInputBase-root-MuiOutlinedInput-root, .customer_popup_new .MuiAutocomplete-hasClearIcon.muiltr-ei7t0p-MuiAutocomplete-root .MuiOutlinedInput-root, .customer_popup_new .muiltr-g6a3os-MuiInputBase-root-MuiOutlinedInput-root{
    background: #fff !important;    
    border: 1px solid #e2e1e5 !important;
    padding: 6px 9px !important;
    height: 43px !important;
}
.customer_popup_new .muiltr-1yz9rep-MuiInputBase-root-MuiOutlinedInput-root {
    background: #fff !important;
    border: 1px solid #e2e1e5 !important;
}
.create-customer .new-customers p.MuiFormHelperText-root {
    color: rgb(237, 202, 51);
}
.create-customer .MuiTypography-h6 {
    font-weight: 500;
    margin: 0;
    line-height: 22px;
    padding: 0;
}
.create-customer .addnewjobpage .flex.mx-6 {
    margin: 0 0 12px;
}
.create-customer .edit-customers .MuiFormControl-fullWidth .MuiInputBase-formControl div.MuiOutlinedInput-input {
    padding: 5px !important;
}
.profile-screenshot {
    margin: 0 0 70px;
}
.customer-modal-topbar {
    padding: 35px 24px 0;
}
.customer-balance-modal button.invoicePrintbtn.customer-balance-btn {
    width: 40px;
    height: 38px;
}
.customer-balance-modal .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiTableContainer-root {
    padding: 0;
    border: unset;
}
.customer-balance .MuiAccordionSummary-root.MuiButtonBase-root.MuiAccordionSummary-gutters th, .customer-balance .MuiAccordionSummary-root.MuiButtonBase-root.MuiAccordionSummary-gutters td {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.customer-balance .MuiAccordionSummary-root.MuiButtonBase-root.MuiAccordionSummary-gutters {
    min-height: unset !important;
}

.dashboard-table .MuiCollapse-wrapperInner .MuiBox-root {
    margin: 0;
}

.recouncile-modal .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    max-width: unset !important;
    width: 45%;
    overflow: unset;
}
.recouncile-modal h2.MuiTypography-root.MuiTypography-h6.MuiDialogTitle-root{
    font-weight: 700;
}

.jobstable table.MuiTable-root th span {
    font-weight: 700;
}
.addnewjobpage {
    padding-top: 20px !important;
}

.archive-popup p.MuiDialogContentText-root.MuiTypography-body1 {
    margin: 0 0 13px;
}
.archive-popup button {
    margin: 0 3px;
}
.archive-popup button p {
    margin: 0 !important;
}
.user-img-upload .MuiBox-root {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.user-img-upload {
    padding: 35px;
}
.typehaul-modal .mobilefileds-form .MuiSelect-select.MuiSelect-outlined {
    padding: 4px 5px !important;
}
.typehaul-modal input.MuiOutlinedInput-input.MuiInputBase-input, .typehaul-modal  textarea.MuiOutlinedInput-input.MuiInputBase-input {
    padding: 4px !important;
}
.typehaul-modal .mobilefileds-form label.MuiInputLabel-root.MuiInputLabel-formControl {
    top: -4px !important;
}
.typehaul-modal textarea.MuiOutlinedInput-input.MuiInputBase-input {
    height: 70px !important;
}
.typehaul-modal button.MuiButton-root.MuiButton-contained {
    padding: 7px 30px;
    font-weight: 600;
}

.recouncile-modal ul.MuiList-root.MuiList-padding li form.MuiBox-root .MuiFormControl-root {
    width: 100%;
}
.recouncile-modal ul.MuiList-root.MuiList-padding li  form.MuiBox-root {
    width: calc(100% - 18%) !important;
}
.recouncile-modal ul.MuiList-root.MuiList-padding li .MuiListItemText-root {
    width: 18%;
    flex: unset;
}
.recouncile-modal ul.MuiList-root.MuiList-padding li {
    flex-wrap: wrap;
}
.recouncile-modal ul.MuiList-root h4 {
    font-weight: 600;
    margin: 20px 0 0;
    color: #000;
}
.recouncile-modal ul.MuiList-root.MuiList-padding {
    padding-top: 0;
}
.recouncile-modal ul.MuiList-root.MuiList-padding li form.MuiBox-root input {
    padding: 4px !important;
}
.recouncile-modal ul.MuiList-root.MuiList-padding li form.MuiBox-root label {
    top: -4px !important;
    font-size: 12px;
}
.recouncile-modal h3 {
    font-weight: 600;
    color: #000;
    font-size: 17px;
    padding-top: 12px;
}

.main-column.main-column1 {
    width: 40%;
}
.main-column.main-column2 {
    width: 30%;
}
.main-column.main-column3 {
    width: 30%;
}
.new-uninvoiced-details {
    flex-wrap: wrap;
}
.archive-text-icon img {
    order: 2;
}
.archive-text-icon {
    display: flex;
    align-items: center;
}
.archive-text-icon {
    display: flex;
    align-items: center;
}
.main-column3-inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
}
.main-column.main-column3 .user-edit-detail {
    height: 100%;
}
.empty-text-dashboard p.MuiTypography-root {
    width: 100%;
    text-align: left;
}
.empty-text-dashboard .p-24 {
    padding-left: 0;
    padding-right: 0;
}


.ticket-email-poup button.MuiButton-root.absolute {
    top: unset;
    right: 32px;
    bottom: 32px;
}
.ticket-email-poup .MuiBox-root {
    padding-bottom: 62px;
}
.ticket-email-poup h2#transition-modal-title {
    font-weight: 600;
    font-size: 27px;
    margin: 0 0 15px;
}
.ticket-email-poup .react-multi-email {
    margin: 0 0 12px;
}
.driver-snd-popup .massage-type {
    padding-bottom: 30px;
}
.driver-snd-popup button.MuiButton-root.MuiButton-contained {
    width: unset !important;
    padding: 7px 28px;
}
.driver-snd-popup .MuiOutlinedInput-root textarea.MuiOutlinedInput-input {
    padding: 3px 4px 4px;
}
.mobilefileds-form .p-0.MuiDialogContent-root.muiltr-ypiqx9-MuiDialogContent-root {
    height: 66vh;
}

.popup-close {
    position: absolute;
    top: 20px;
    right: 10px;
}
.popup-close svg {
    width: 35px;
    cursor: pointer;
    fill:#000;
}

.invitemobileuserbox .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    width: 45%;
}
.adddriverbox input#send_to {
    padding: 0 !important;
}
.invitemobileuserbox div.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input {
    text-align: left;
    padding-left: 4px;
}
/* .add-owner-operator input.MuiOutlinedInput-input.MuiInputBase-input {
    padding: 4px 0 !important;
} */
.add-driver-popup .adddriverbox input#send_to {
    padding: 9px !important;
}
.add-owner-operator .popup-close svg {
    width: 35px;
    cursor: pointer;
    fill: #fff;
}
.add-owner-operator .popup-close {
    top: 10px;
}

@media screen and (max-width:667px) {
    .pdf_new  .MuiTypography-h2 {
        font-size: 27px !important;
    }
    .pdf_new .muiltr-zow5z4-MuiGrid-root {
        margin-top: 0px;
    }
    .pdf_new .newinvoice-ui table tr td {
        font-size: 14px;
        padding: 12px;
    }
    .pdf_new .new-invoice-bottmright td {
        font-size: 14px;
    }
    .pdf_new .pdf_lodz_logo {
        width: 100px;
    }
}
@media screen and (max-width:562px) { 
    .pdf_new .muiltr-zow5z4-MuiGrid-root {
        flex-direction: column;
    }
    .pdf_new .muiltr-zow5z4-MuiGrid-root .muiltr-s161zg-MuiGrid-root {
        margin: 0px;
    }
    .pdf_new .muiltr-zow5z4-MuiGrid-root>.MuiGrid-item {
        text-align: left;
    }
    .pdf_new .MuiTypography-h2 {
        font-size: 20px !important;
    }
    .pdf_new .w-full-s {
        height: 70px;
    }
    .pdf_new .muiltr-1hb30l8-MuiPaper-root {
        max-width: 400px;
        overflow: scroll;
    }
}
@media screen and (max-width:480px) {  
    .pdf_new .muiltr-mhc70k-MuiGrid-root {
        flex-direction: column;
    } 
    .pdf_new .muiltr-11lq3yg-MuiGrid-root {
        flex-direction: column;
    }
    .pdf_new .muiltr-11lq3yg-MuiGrid-root  .muiltr-1744jwa-MuiGrid-root {
        margin-bottom: 15px;
    }
    .pdf_new .muiltr-11lq3yg-MuiGrid-root .muiltr-1744jwa-MuiGrid-root .mainpdfview .MuiTypography-body1 {
        text-align: left;
    }
    .pdf_new .new-invoice-bottmtable {
        max-width: 480px;
        overflow: scroll;
        flex-direction: row;
    }
}
.pdf_lodz_logo {
    width: 140px;
    margin-left: 10px;
}
.h-max-344 {
    height: 344px;
}