/*top right bottom left*/
@page {
    size: A4;
    margin: 17mm 8mm 3mm 8mm;    
  }


  .header{
    display: none;
  }

  .footer{
    display: none;
   
  }
  

@media print{
    
    .Report {
      justify-content: center;
       overflow: visible !important;
       
       
    }
    .header{
        display: inherit;
        position: fixed;
        top: 0cm;
    }
    .footer{
        display: flex;
        position: fixed;
        padding-top:10cm;
        bottom: 0.0001cm;
        right: 0.5cm;        
        break-inside: avoid;
       
      
        
    }

    .table{
        height: 100px;
    }

    .job{
        
       /* display: inline-block;*/
        break-inside: avoid;
    
    }

    .halfborder {
      border-top:1px solid black;
      position: relative;
    }
    .halfborder:after {
      padding:0;margin:0;display:block;/* probably not really needed? */
      content: "";
      width:50%;
      height:1.1px;
      background-color:white;
      position: absolute;
      right:0;
      top:-1px;
    }

    .pagebreak tr td{
        page-break-before: auto !important;
        page-break-after: auto !important;
        page-break-inside: avoid !important;
    }
   
  
}