.dashboaednav .MuiList-root li, .dashboaednav .MuiList-root a {
    border-bottom: 1px solid #000;
    border-radius: 0;
}
.dashboaedlogo {
    padding-top: 20px;
    position: absolute;
    bottom: 0px;
    background: #fff;
    padding: 18px 0;
}

@media only screen and ( max-width : 1340px ) {
    .dashboaedlogo {
        position: static;
    }
}


.dashboaedenroolment {
    padding-top: 20px;
    position: absolute;
    bottom: 0px;
    background: #fff;
    padding: 55px 0;
}

@media only screen and ( max-width : 1340px ) {
    .dashboaedenroolment {
        position: static;
    }
}

