
.ModalGenerateInvoicesPrint .MuiPaper-elevation .InvoicesPrint {
    /* overflow: auto; */
    /* min-height: 500px; */
}

/* .bgdiv.MuiGrid-item {
    padding-left: 50px;
} */
/* .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-1.verticalText.muiltr-1fdpgu7-MuiGrid-root {
    max-width: 100% !important;
    width: 100%;
    position: absolute;
    left: 65px;
    top: 10%;
    transform-origin: 0 0;
    transform: rotate(90deg);
} */

.customloader .muiltr-buvykw-MuiPaper-root-MuiDialog-paper {
    background: transparent;
    box-shadow: none;
}

.customloader .muiltr-buvykw-MuiPaper-root-MuiDialog-paper p {
    color: #fff;
}

.invoicePrintbtn {
    width: 50px;
    height: 50px;
    background: #eacd2f;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}

.invoicePrintbtns {
    display: flex;
    align-items: center;
}

.invoicePrintbtns div {
    margin-right: 15px;
    font-family: Arial, Helvetica, sans-serif;
}
.muiltr-kxch4c-MuiTableCell-root,
.muiltr-koj65o-MuiTableCell-root {
    border-bottom: 0;
}


@page  {
    margin-top: 20px;
    height:auto;
    min-height:auto;
    padding: 0;
  }

@media print {
      .padding-0{
        line-height: 10px;
    }
    .ModalGenerateInvoicesPrint .MuiPaper-elevation .InvoicesPrint {
        overflow: auto;
        min-height: unset;
    }
    .mainpdfview{
        height: auto;
        min-height: auto;
        page-break-after: auto;
    }
    .mainpdfview,
    .ModalGenerateInvoicesPrint {
        position: relative;
    }
    .muiltr-2bn1jt-MuiTableCell-root {
        font-size: 10px;
        /* padding: 10px; */
    }

    .muiltr-koj65o-MuiTableCell-root,
    .muiltr-kxch4c-MuiTableCell-root {
        font-size: 10px;
        /* padding: 10px; */
    }

    /* .muiltr-1bppsaa-MuiTable-root {
        width: 100% !important;
    } */

    .muiltr-hr4cd2-MuiTypography-root {
        font-size: 14px;
    }

    .muiltr-1y5dozm-MuiTypography-root {
        font-size: 12px;
    }
    .muiltr-1m3lzqm-MuiPaper-root,
    .muiltr-9pih9r-MuiPaper-root-MuiCard-root {
        box-shadow: none;
        min-height: auto;
         height: auto;
    }
    .newinvoice-ui table tr td {
        font-size: 14px;
    }
    .newinvoice-ui table tr th {
        font-size: 14px;
    }
    .new-invoice-bottmright td {
        font-size: 14px;
    }
    .topbatchno p {
        font-size: 14px;
    }
    .MuiTypography-root.big-title {
        font-size: 36px !important;
    }
    .MuiTypography-root.sub-title {
        font-size: 22px !important;
    }
    .w-full.ModalGenerateInvoicesPrint .MuiPaper-rounded {
        padding-left: 20px !important;
    }
    .new-invoice-bottmleft {
        border: 1px solid #000;
    }
    .new-invoice-bottmright tr {
        border-bottom: 1px solid #000;
    }
    .newinvoice-ui table tr{
        border-right: 1px solid #000 !important;
    }
    .newinvoice-ui table tr td {
        font-size: 18px !important;
    }
    .MuiGrid-root.mainpdfview {
        width: 100%;
        margin: 0;
        padding: 0 !important;
    }
    .MuiGrid-root.cstPdf {
        padding-left: 0 !important;
    }
    .newinvoice-ui table tr td {
        padding: 10px 0;
    }
    .newinvoice-ui table tr th:last-child,
    .newinvoice-ui table tr td:last-child{
        border-right: 1px solid #000;
    }
}

/* Ticket View */