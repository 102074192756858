.customer-balance .customer-balance-table .customeraccordion:nth-child(odd) {
    background: #f7f7f7 !important;
}
.customer-balance .customer-balance-table .customeraccordion {
    transition: all 0.5s;
}
.customer-balance .customer-balance-table .customeraccordion:hover{
    background: #eeeeee !important;
}
.customer-balance table.MuiTable-root.customer-balance-table  .MuiAccordionSummary-root {
    border: 0;
    background: transparent;
}
.customer-balance .customer-balance-table > thead tr {
    background: #e7cc0b;
}
.customer-balance .customer-balance-table > thead > tr > th {
    width: 200px !important;
}
table.MuiTable-root.customer-balance-table .MuiAccordionSummary-content th, table.MuiTable-root.customer-balance-table .MuiAccordionSummary-content td {
    width: 200px;
    display: inline-block;
    font-weight: 400;
}
.customer-balance table.MuiTable-root.customer-balance-table .MuiCollapse-vertical th {
    background: #e9e9e9;
    color: #000;
    font-weight: 500;
}
.customer-balance table.MuiTable-root.customer-balance-table .MuiCollapse-vertical td {
    font-weight: 400;
}

.customer-balance table.MuiTable-root.customer-balance-table .customer-table th {
    background: #e9e9e9;
    color: #000;
    font-weight: 500;
}
.customer-balance table.MuiTable-root.customer-balance-table .customer-table td {
    font-weight: 400;
}

.grandtotal {
    padding: 15px 18px;
    padding-right: 50px;
    background: #222;
    color: #fff;
}
h2.customer-balance-heading {
    font-weight: 600;
    font-size: 22px;
}
.invoicePrintbtn.customer-balance-btn {
    position: absolute;
}
.customer-balance .MuiPaper-rounded {
    padding: 10px;
}
td.MuiTableCell-alignRight {
    text-align: right !important;
    padding: 16px !important;
}
 table.customer-table td {
    padding: 10px 16px;
    text-align: left;
}
table.customer-table td {
    background: #f9f9f9;
}
.MuiAccordionDetails-root {
    padding: 0;
}
table.customer-table td td {
    border: 0;
    padding: 0;
}
.grandtotal span {
    width: 197px;
    display: inline-block;
    text-align: right;
}
.grandtotal span:first-child {
    text-align: left;
}
.grandtotal {
    padding: 15px 18px;
    padding-right: 50px;
    background: #222;
    color: #fff;
    display: inline-block;
    width: 100%;
}
/* .fixedtoprintin-footer {
    position: re;
    bottom: 0;
    left: 0;
    right: 0;
  } */
  
@media print {
    .customer-balance .customer-balance-table > thead > tr > th {
        width: 95px !important;
        text-align: left !important;
        font-size: 12px;
        line-height: 1;
    }
    .customer-balance .customer-balance-table > thead > tr > th:first-child {
        width: 198px !important;
    }
    table.MuiTable-root.customer-balance-table .MuiAccordionSummary-content th {
        width: 198px  !important;
        text-align: left !important;
        font-size: 10px;
        line-height: 1;
        padding: 0 10px;
    }
    h2.customer-balance-heading {
        font-size: 18px;
    }
    table.MuiTable-root.customer-balance-table .MuiAccordionSummary-content td {
        width: 95px;
        text-align: left !important;
        font-size: 10px;
        line-height: 1;
        padding: 0 10px;
    }
    table.MuiTable-root.customer-balance-table .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper {
        display: none;
    }
    .customer-balance .customer-balance-table .customeraccordion {
        padding: 0;
    }
    .customeraccordion {
        margin-bottom: 0;
    }
    .customer-balance .MuiPaper-rounded {
        padding: 0;
        border: 0;
    }
    .customer-balance table.MuiTable-root.customer-balance-table .MuiAccordionSummary-root{
        min-height: 24px;
    }
    .customer-balance-table .MuiAccordionSummary-content {
        margin: 0;
    }
    table.customer-table td {
        font-size: 13px;
    }
    table.customer-table td td {
        padding: 0;
    }
    .grandtotal span {
        width: 95px;
        font-size: 12px;
        text-align: center;
    }
    .grandtotal span:first-child {
        width: 170px;
    }
    .grandtotal span:last-child {
        text-align: right;
    }
    .customer-balance .MuiPaper-rounded {
        padding: 0;
        margin: 0;
    }
    .MuiAccordionSummary-gutters {
        min-height: 25px !important;
    }
    td.MuiTableCell-alignRight {
        padding: 8px 16px !important;
    }
    .customer-balance table.MuiTable-root.customer-balance-table .customer-table th {
        padding: 5px 16px;
    }
    .customer-balance .customer-balance-table > thead > tr > th {
        padding: 8px 16px;
        font-size: 12px !important;
    }
    .grandtotal {
        padding: 5px 10px;
        padding-right: 10px;
    }
    .customer-balance table.MuiTable-root.customer-balance-table .customer-table td,
    .customer-balance table.MuiTable-root.customer-balance-table .customer-table th {
        font-size: 10px;
    }
    .MuiDialogContent-root.customer-balance {
        padding: 10px;
    }
    h2.customer-balance-heading {
        font-size: 16px;
        padding: 0;
        margin-bottom: 10px;
    }
    /* .fixedtoprintin-footer{
        page-break-inside: avoid;
    } */
    .page-break {
        page-break-before: auto;
      }
}