.TypeAheadDropDown{
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgb(190, 187, 187);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.73);
   }
    
   .TypeAheadDropDown input{
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.73);
    padding: 10px 5px ;
    box-sizing: border-box;
    outline: none;
    
   }
   .TypeAheadDropDown ::placeholder{
    color: rgb(133, 129, 129);
   }
   .TypeAheadDropDown ul::before{
    content: '';
   }
    
   .TypeAheadDropDown ul{
   list-style-type: none;
   text-align: left;
   margin: 0;
   padding: 0;
   border-top: 1px solid gray;
   }
    
   .TypeAheadDropDown li{
    padding: 10px 5px ;
    cursor: pointer;
   }
    
   .TypeAheadDropDown li:hover{
    background: lightgray;
    text-decoration: underline;
   }

